@font-face {
  font-family: Nova;
  src: url(https://fonts.googleapis.com/css2?family=Nova+Flat&display=swap);
}

.boxsizingBorder {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  margin: 0;
}

.mar-1 {
  margin: 1rem;
}
.mary-1 {
  margin: 1rem 0;
}
.marx-1 {
  margin: 0 1rem;
}
.pad-1 {
  padding: 1rem;
}
.pady-1 {
  padding: 1rem 0;
}
.padx-1 {
  padding: 0 1rem;
}
.mar-2 {
  margin: 2rem;
}
.mary-2 {
  margin: 2rem 0;
}
.marx-2 {
  margin: 0 2rem;
}
.pad-2 {
  padding: 2rem;
}
.pady-2 {
  padding: 2rem 0;
}
.padx-2 {
  padding: 0 2rem;
}
.mar-3 {
  margin: 3rem;
}
.mary-3 {
  margin: 3rem 0;
}
.marx-3 {
  margin: 0 3rem;
}
.pad-3 {
  padding: 3rem;
}
.pady-3 {
  padding: 3rem 0;
}
.padx-3 {
  padding: 0 3rem;
}
.mar-4 {
  margin: 4rem;
}
.mary-4 {
  margin: 4rem 0;
}
.marx-4 {
  margin: 0 4rem;
}
.pad-4 {
  padding: 4rem;
}
.pady-4 {
  padding: 4rem 0;
}
.padx-4 {
  padding: 0 4rem;
}
.mar-5 {
  margin: 5rem;
}
.mary-5 {
  margin: 5rem 0;
}
.marx-5 {
  margin: 0 5rem;
}
.pad-5 {
  padding: 5rem;
}
.pady-5 {
  padding: 5rem 0;
}
.padx-5 {
  padding: 0 5rem;
}
.container {
  max-width: 1600px;
  margin: auto;
  overflow: hidden;
  padding: 0 2rem;
}
.pointer {
  cursor: pointer;
}
.x-large {
  font-size: 4.5rem;
  line-height: 1.2;
}
.large {
  font-size: 2.5rem;
  line-height: 1.2;
}
.medium {
  font-size: 1.5rem;
  line-height: 1.2;
}
.lead {
  font-size: 1.5rem;
  cursor: default;
}
.section-title {
  width: 100%;
}
.text-primary {
  color: #e0e0e0;
}
.text-light1 {
  color: #e0e0e0;
}
.text-light2 {
  color: #aed7da;
}
.text-med1 {
  color: #4d4d4d;
}
.text-med2 {
  color: #268fa1;
}
.text-dark1 {
  color: #121313;
}
.text-dark2 {
  color: #0e201e;
}
.text-orange {
  color: #ff8b1f;
}
.text-mag {
  color: #d61fb5;
}
.text-blue {
  color: #2963f5;
}
.text-danger {
  color: #d42323;
}
.round-img {
  object-fit: cover;
  object-position: top;
  border-radius: 50%;
  height: 200px;
  width: 200px;
}
.line {
  height: 1px;
  background: #4d4d4d;
  margin: 1.5rem 0;
}
.bg-light1 {
  background-color: #e0e0e0;
  color: #121313;
  border: #ccc 1px solid;
}
.bg-light2 {
  background-color: #aed7da;
  color: #121313;
  border: #ccc 1px solid;
}
.bg-dark1 {
  background-color: #121313;
  color: #e0e0e0;
}
.bg-dark2 {
  background-color: #0e201e;
  color: #e0e0e0;
}
.bg-orange {
  background-color: #ff8b1f;
  color: #e0e0e0;
}
.bg-mag {
  background-color: #d61fb5;
  color: #e0e0e0;
}
.bg-blue {
  background-color: #2963f5;
  color: #e0e0e0;
}
.dark-overlay {
  height: 100%;
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.8);
  z-index: -1;
}
.btn {
  display: inline-block;
  background: #268fa1;
  color: #121313;
  padding: 0.4rem 1.3rem;
  border: solid 1px #4d4d4d;
  cursor: pointer;
  font-size: 1rem;
  margin-right: 0.5rem;
  outline: none;
  transition: all 0.2s ease-in;
}
.btn.btn-light1 {
  background: #e0e0e0;
  color: #121313;
}
.btn.btn-light1:hover {
  background: gray;
}
.btn.btn-light2 {
  background: #aed7da;
  color: #0e201e;
}
.btn.btn-light2:hover {
  background: #268fa1;
}
.btn.btn-dark1 {
  background: #121313;
  color: #e0e0e0;
}
.btn.btn-dark1:hover {
  background: gray;
  color: #121313;
}
.btn.btn-dark2 {
  background: #0e201e;
  color: #aed7da;
}
.btn.btn-dark2:hover {
  background: #268fa1;
}
.btn.btn-med1 {
  background: #4d4d4d;
  color: #e0e0e0;
}
.btn.btn-med1:hover {
  background: gray;
  color: #e0e0e0;
}
.btn.btn-med2 {
  background: #268fa1;
  color: #121313;
}
.btn.btn-med2:hover {
  background: #56c4d7;
  color: #aed7da;
}
.btn.btn-orange {
  background: #ff8b1f;
  color: #121313;
}
.btn.btn-orange:hover {
  background: #ffc085;
  color: #4d4d4d;
}
.btn.btn-mag {
  background: #d61fb5;
  color: #121313;
}
.btn.btn-mag:hover {
  background: #ea71d4;
  color: #268fa1;
}
.btn.btn-blue {
  background: #2963f5;
  color: #121313;
}
.btn.btn-blue:hover {
  background: #8aaafa;
  color: #268fa1;
}
.btn:hover {
  background: #444747;
  color: #fff;
}
.btn.btn-landing {
  width: 100px;
  font-weight: bold;
}
.btn.btn-landing:hover {
  background: #ff8b1f;
  color: #121313;
}
.alert {
  padding: 0.5rem;
  margin: 1rem;
  opacity: 0.9;
  background: #4d4d4d;
  color: #e0e0e0;
}
.alert.alert-success {
  background: #2963f5;
}
.alert.alert-danger {
  background: #ff8b1f;
  color: #121313;
}
.badge {
  font-size: 0.8rem;
  padding: 0.1rem;
  text-align: center;
  margin: 0.3rem;
  background: #4d4d4d;
}
.badge.badge-orange {
  background: #ff8b1f;
}
.badge.badge-med2 {
  background: #268fa1;
}
.badge.badge-light2 {
  background: #aed7da;
}
.table th,
.table td {
  padding: 1rem;
  text-align: left;
}
.table th {
  background: #aed7da;
  color: #0e201e;
}
.form {
  padding: 0 5%;
}
.form-group {
  margin: 1.2rem 0;
}
.form-text {
  display: block;
  margin-top: 0.3rem;
  color: #aed7da;
}
.form input[type="text"],
.form input[type="email"],
.form input[type="password"],
.form input[type="date"],
.form select,
.form textarea {
  display: block;
  width: 100%;
  padding: 0.4rem;
  font-size: 1.2rem;
  border: 2px solid #4d4d4d;
}
.form input[type="submit"] {
  font: inherit;
}
.form .social-input {
  display: flex;
}
.form .social-input i {
  padding: 0.5rem;
  width: 4rem;
  color: #e0e0e0;
}
* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}
body {
  font-family: "Nova Flat", cursive;
  font-size: 1rem;
  line-height: 1.6;
  background-color: #121313;
}
a {
  text-decoration: none;
  color: #aed7da;
}
ul {
  list-style: none;
}
img {
  width: 100%;
}
input {
  background: #4d4d4d;
  color: #e0e0e0;
  outline: none;
  border: none;
}
textarea {
  background: #4d4d4d;
  color: #e0e0e0;
  outline: none;
  border: none;
  resize: none;
}

.skillTooltip {
  display: flex;
  flex-direction: column;
  width: 300px;
  opacity: 1 !important;
  border: solid 1px #56c4d7;
}

.skillTooltip p {
  color:#268fa1;
  font-weight: bold;
}

.skillTooltip h1 {
  width: 100%;
  text-align: center;
  color: #ff8b1f;
}

.skillTooltip div {
  margin-top: 0.5rem;
}

.skillTooltip div p {
  color: #2963f5;
}

.navbar {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0.7rem 2rem;
  z-index: 1;
  width: 100%;
  top: 0;
  border-bottom: solid 1px #2963f5;
  background: #121313;
}
.navbar ul {
  display: flex;
}
.navbar a {
  padding: 0.45rem;
  margin: 0 0.25rem;
}
.navbar a:hover {
  color: #ff8b1f;
}
.landing {
  position: relative;
  height: 80vh;
}

.landing-bg {
  position: fixed;
  height: 100vh;
  object-fit: cover;
  z-index: -1;
  left: 0;
  top: 0;
}

.landing-title {
  color: #ff8b1f;
  margin-bottom: 2rem;
}
.landing-inner {
  color: #e0e0e0;
  height: 100%;
  display: flex;
  flex-direction: column;
  width: 80%;
  margin: auto;
  align-items: center;
  justify-content: center;
  text-align: center;
}
.profile {
  display: grid;
  grid-template-columns: 2fr 4fr 2fr;
  gap: 2rem;
  align-items: center;
  padding: 1rem;
  line-height: 1.8;
  margin-bottom: 1rem;
  background: #0e201e;
  color: #aed7da;
  border: 1px solid #4d4d4d;
}
.profile-grid {
  display: grid;
  grid-template-areas:
    "top top"
    "about about"
    "exp edu"
    "github github";
  gap: 1rem;
}
.profile-grid .profile-top {
  grid-area: top;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
}
.profile-grid .profile-top img {
  width: 250px;
  height: 250px;
}
.profile-grid .profile-top .icons a {
  color: white;
  margin: 0 0.3rem;
}
.profile-grid .profile-top .icons a:hover {
  color: #d61fb5;
}
.profile-grid .profile-about {
  grid-area: about;
  text-align: center;
}
.profile-grid .profile-about .skills {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}
.profile-grid .profile-exp {
  grid-area: exp;
}
.profile-grid .profile-edu {
  grid-area: edu;
}
.profile-grid .profile-exp > div,
.profile-grid .profile-edu > div {
  margin-bottom: 1rem;
  padding-bottom: 1rem;
  border-bottom: #ccc 1px dotted;
}
.profile-grid .profile-exp:last-child,
.profile-grid .profile-edu:last-child {
  border: none;
}
.profile-grid .profile-exp p,
.profile-grid .profile-edu p {
  margin: 0.5rem 0;
}
.profile-grid .profile-github {
  grid-area: github;
}
.profile-grid .profile-github .repo {
  display: flex;
}
.profile-grid .profile-github .repo > div:first-child {
  flex: 7;
  flex-basis: 70%;
}
.profile-grid .profile-github .repo > div:last-child {
  flex: 3;
  flex-basis: 20%;
}
.post-form-header {
  padding: 0.5rem;
}
.post {
  padding: 1rem;
  display: grid;
  grid-template-columns: 1fr 4fr;
  gap: 2rem;
  align-items: center;
}
.post > div:first-child {
  text-align: center;
}
.post img {
  width: 150px;
  height: 150px;
}

.campaignModal {
  background: #121313;
  color: #aed7da;
  position: absolute;
  top: 7%;
  left: 25%;
  right: 25%;
  min-height: 150px;
  padding: 1rem;
  border: solid 2px #268fa1;
  display: flex;
  flex-direction: column;
  text-align: center;
  align-items: center;
}

.campaignModal h1 {
  color: #2963f5;
}

.campaignModal .newCampForm {
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: center;
  align-items: center;
  margin: 2rem 0rem;
}

.campaignModal .newCampForm > div:first-child {
  flex: 1 50%;
}

.campaignModal .newCampForm > div:last-child {
  flex: 1 50%;
  display: flex;
  justify-content: space-evenly;
}

.campaignModal .newCampForm h2 {
  color: #ff8b1f;
}

.campaignModal .newCampForm > div:last-child input {
  padding: 0.5rem;
  width: 80%;
  font-size: 18pt;
  text-align: center;
  border-radius: 10px;
}

.campaignModal .newCampForm > div:last-child button {
  width: 150px;
  height: 40px;
  font-size: 16pt;
  border-radius: 10px;
}

.campaignModal .newCampForm > div:last-child select {
  width: 120px;
  height: 40px;
  padding-left: 1rem;
  font-size: 16pt;
  border-radius: 10px;
}

.campaignModal .newCampButtons {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.campaignModal .newCampButtons .newCampConfirm {
  width: 200px;
  height: 40px;
  font-size: 16pt;
  margin: 1rem 1rem;
  background: #56c4d7;
  color: #121313;
  border-radius: 10px;
  font-weight: bold;
}

.campaignModal .newCampButtons .newCampCancel {
  width: 200px;
  height: 40px;
  font-size: 16pt;
  margin: 1rem 1rem;
  background: #d42323;
  color: #121313;
  border-radius: 10px;
  font-weight: bold;
}

.campaignOverlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(49, 49, 49, 0.6);
}

.dash-char-list {
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: flex-start;
  border: solid 1px #4d4d4d;
}
.dash-char-list h2 {
  color: #aed7da;
}
.dash-char-item {
  display: flex;
  flex-direction: column;
  background: #0e201e;
  padding: 1rem;
  margin: 10px 0px;
}
.dash-char-item h3 {
  color: #268fa1;
}

.dash-char-item .char-item-top {
  display: flex;
  flex-direction: row;
}

.dash-char-item .header-img {
  text-align: center;
}
.dash-char-item img {
  vertical-align: middle;
  width: 120px;
  border-radius: 50%;
  border: 1px solid #268fa1;
}

.dash-char-item .dash-info {
  flex: 1 25%;
  display: flex;
  flex-direction: column;
  height: 100%;
  padding: 1rem 1rem 0rem 1rem;
}

.dash-char-item .dash-char-name {
  font-size: 1.5rem;
}

.dash-char-item .dash-info .char-created {
  margin-top: auto;
}

.dash-char-item .dash-skill-info {
  flex: 1 20%;
}

.dash-char-item .dash-skill-item {
  display: flex;
  flex-direction: row;
  padding: 0.25rem;
  margin: 0.25rem;
}
.dash-char-item .dash-skill-item div:first-child {
  flex: 1 70%;
  align-self: flex-start;
  color: #aed7da;
}
.dash-char-item .dash-skill-item div:last-child {
  flex: 1 30%;
  text-align: left;
  color: #ff8b1f;
}
.dash-char-item .dash-char-panel3 {
  flex: 1 30%;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.dash-char-item .dash-char-panel3 .char-item-health {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
}
.dash-char-item .dash-char-panel3 .char-item-health .dash-health {
  display: flex;
  flex-direction: column;
  text-align: center;
  justify-content: center;
}

.dash-char-item .dash-char-panel3 .char-item-health .dash-health h3 {
  color:#2963f5;
}

.dash-char-item .dash-char-panel3 .char-item-health .dash-health .health-numbers {
  display: flex;
  flex-direction: row;
  font-size: 1.25rem;
  color: #4d4d4d;
}
.dash-char-item .dash-char-panel3 .char-item-main-hand {
  width: 100%;
  padding-top: 1rem;
}
.dash-char-item .dash-char-panel3 .char-item-main-hand div {
  display: flex;
  flex-direction: row;
}
.dash-char-item .dash-char-panel3 .char-item-main-hand div .weap-name {
  flex: 1 50%;
  color: #e0e0e0;
  font-size: 1rem;
}

.dash-char-item .dash-char-panel3 .char-item-main-hand div .weap-dd {
  flex: 1 50%;
  color: #ff8b1f;
  font-size: 1rem;
}

.dash-char-item .dash-char-panel3 .char-item-main-hand div .mod-area {
  display: flex;
  padding: 0.25rem;
  justify-content: center;
}
.dash-char-item .dash-char-panel3 .char-item-main-hand div .mod {
  margin: 0 0.5rem;
  height: 30px;
  width: 30px;
  border: solid 1px #4d4d4d;
  border-radius: 50%;
}
.dash-char-item .dash-char-panel3 .char-item-main-hand div .equipped-mod {
  background: #ff8b1f;
}
.dash-char-item .dash-char-buttons {
  flex: 1 15%;
  display: flex;
  flex-direction: column;
  padding: 1rem;
  justify-content: space-evenly;
}

.dash-char-item .dash-char-buttons .char-link {
  text-align: center;
  border: solid 1px #4d4d4d;
  background: #121313;
  color: #e0e0e0;
  padding: 0.25rem 0;
  margin-bottom: 0.25rem;
  cursor: pointer;
}
.dash-char-item .dash-char-buttons .delete-button {
  background: #30060f;
  text-align: center;
  border: solid 1px #4d4d4d;
  color: #e0e0e0;
  padding: 0.55rem 0;
  margin-bottom: 0.25rem;
  cursor: pointer;
}

.dash-char-item .char-item-bottom {
  padding: 1rem;
  color:#e0e0e0;
}

.createCharWrapper {
  display: flex;
  flex-direction: row;
}

.createCharWrapper .createCharColumn {
  display: flex;
  flex: 1 33%;
  flex-direction: column;
  border: solid 1px #aed7da;
  padding: 1rem;
  transition: background-color 0.5s, color 0.5s;
  color:#7b7b7b;
  justify-content: center;
  text-align: center;
  align-items: center;
}

.createCharWrapper .createCharColumn:hover {
  background-color: #0e201e;
  color:#aed7da;
}

.createCharWrapper .createCharColumn h1 {
  color: #ff8b1f;
  margin-bottom: 2rem;
}

.createCharWrapper .createCharColumn .createCharConfirm {
  width: 200px;
  background: #ff8b1f;
  color: #121313;
  font-size: 1.2rem;
  font-weight: bold;
  border: solid 1px #aed7da;
  border-radius: 0.5rem;
  text-align: center;
  padding: 0.5rem;
  margin-top: 2rem;
  cursor: pointer;
}

.char-header {
  display: flex;
  flex-direction: column;
  background: #0e201e;
}

.char-header .header-info {
 padding: 1rem;
}

.char-header .header-info .char-level {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.char-header .header-info .char-level div:first-of-type span {
  margin: 0rem 0.25rem;
}

.char-header .header-info .char-level .level-up-but {
  text-align: center;
  background: #268fa1;
  color: #121313;
  border: solid 1px #aed7da;
  border-radius: 0.5rem;
  width: 80px;
  height: 80%;
  padding: 0.4rem 0.5rem;
  margin-left: 1rem;
  font-size: 0.8rem;
  font-weight: bold;
}
.char-header .header-info .char-level .level-up-but:hover {
  cursor: pointer;
  background: #ff8b1f;
}

.char-header .header-menu {
  display: flex;
  flex-flow: row;
  align-items: flex-end;
  justify-content: flex-end;
  text-align: center;
}
.char-header .header-menu .header-tab {
  color: #aed7da;
  padding: 0.5rem 1rem;
  cursor: pointer;
  font-size: 1rem;
}
.char-header .header-menu .header-tab:hover {
  background: #aed7da;
  color: #121313;
}
.char-header .header-menu .header-tab .active {
  background: #268fa1;
  color: #e0e0e0;
}
.char-sheet-main-panel {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  padding: 1rem;
  border: solid 1px #0e201e;
  color: #e0e0e0;
}

.char-sheet-main-panel .main-row {
  display: flex;
  flex-direction: row;
}
.char-sheet-main-panel .vitals {
  flex: 1 50%;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}
.char-sheet-main-panel .vitals div {
  width: 350px;
  display: flex;
  flex-direction: row;
  margin-top: 0.5rem;
  align-items: center;
  justify-content: flex-end;
}
.char-sheet-main-panel .vitals div .vital-container {
  width: 200px;
  display: block;
  margin-left: 1rem;
  margin-bottom: 0.5rem;
  border: solid 1px #268fa1;
  border-radius: 0.25rem;
}

.char-sheet-main-panel .stats {
  flex: 1 50%;
}

.char-sheet-main-panel .attributes {
  display: flex;
  flex-direction: column;
  text-align: center;
  font-size: 1.2rem;
  margin-bottom: 1rem;
}

.char-sheet-main-panel .attributes .attribute-container {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  color: #ff8b1f;
}

.char-sheet-main-panel .attributes .attribute-container p {
  width: 60px;
  height: 60px;
  font-size: 1.8rem;
  line-height: 3.6rem;
  text-align: center;
  border: solid 1px #268fa1;
  border-radius: 30%;
  color: #e0e0e0;
}
.char-sheet-main-panel .attributes .attribute-container .att-mod {
  position: relative;
  width: 40px;
  padding: 2px 0;
  background: #aed7da;
  border: 1px solid #268fa1;
  color: #121313;
  text-align: center;
  font-size: 1rem;
  border-radius: 50%;
  margin: auto;
  top: -10px;
}
.char-sheet-main-panel .languages {
  flex: 1 50%;
}

.char-sheet-main-panel .languages .lang-container {
  width: 80%;
  padding: 1rem;
  margin: 0rem 1rem;
  border: solid 1px #268fa1;
  border-radius: 0.25rem;
}

.char-sheet-main-panel .other-stats {
  text-align: center;
  color: #2963f5;
  font-size: 1.5rem;
}
.char-sheet-main-panel .other-stats > div:last-child {
  display: flex;
  flex-flow: row;
  justify-content: space-evenly;
  color: #ff8b1f;
}
.char-sheet-main-panel .other-stats > div:last-child p {
  width: 80px;
  height: 40px;
  font-size: 1.5rem;
  line-height: 2.6rem;
  text-align: center;
  border: solid 1px #268fa1;
  border-radius: 30%;
  color: #e0e0e0;
  margin: auto;
}
.char-sheet-main-panel .info-container {
  display: block;
  padding: 1rem;
  margin: 0rem 1rem;
  border: solid 1px #268fa1;
  border-radius: 0.25rem;
}
.char-sheet-main-panel .personality {
  flex: 1 50%;
}
.char-sheet-main-panel .motivation {
  flex: 1 50%;
}
.char-sheet-main-panel .fears {
  flex: 1 50%;
}
.char-sheet-main-panel .backstory {
  flex: 1 100%;
}
.char-sheet-status-panel {
  display: grid;
  grid-template-areas:
    "health health"
    "status status"
    "equipment stims"
    "equipment items";
  gap: 1rem;
  padding: 1rem;
  border: solid 1px #0e201e;
  color: #e0e0e0;
}
.char-sheet-status-panel .health {
  grid-area: health;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
  text-align: center;
}
.char-sheet-status-panel .health-border {
  border: solid 1px #268fa1;
  padding: 0.5rem;
  width: 400px;
  margin: 0.5rem 0;
}

.char-sheet-status-panel .health-fill {
  position: absolute;
  background: #ff8b1f;
  height: 42px;
  transform: translate(0px, 9px);
  z-index: -1;
}
.char-sheet-status-panel .bar-fill {
  position: absolute;
  background: #4d4d4d;
  height: 42px;
  transform: translate(0px, 9px);
  z-index: -1;
}
.char-sheet-status-panel .ac p {
  width: 60px;
  height: 60px;
  font-size: 2rem;
  line-height: 3.6rem;
  text-align: center;
  border: solid 1px #268fa1;
  border-radius: 30%;
  color: #e0e0e0;
}
.char-sheet-status-panel .status {
  grid-area: status;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  text-align: center;
}
.char-sheet-status-panel .status-item {
  width: 250px;
  margin: 1rem;
  border: solid 1px #268fa1;
}
.char-sheet-status-panel .status-item p {
  width: 100%;
  background: #0e201e;
  color: #ff8b1f;
}
.char-sheet-status-panel .status-item div {
  min-height: 100px;
}
.char-sheet-status-panel .status-item div p {
  background: none;
  color: #aed7da;
  border-bottom: solid 1px #4d4d4d;
}
.char-sheet-status-panel .status-item div p:hover {
  background: #ff8b1f;
  color: #121313;
}
.char-sheet-status-panel .equipment {
  grid-area: equipment;
  justify-content: center;
  align-items: center;
  text-align: center;
}
.char-sheet-status-panel .equipment .equip-type {
  text-align: left;
  margin-left: 10px;
  color: #ff8b1f;
}
.char-sheet-status-panel .equipment .armor-container {
  display: grid;
  margin: 0rem 0.5rem 0.5rem 0.5rem;
  padding: 0.5rem 1rem;
  grid-template-columns: 3fr 1fr 3fr;
  grid-template-areas:
    "armor-name ac effects mods"
    "armor-name bar effects mods"
    "armor-type cond effects mods";
  border: solid 1px #268fa1;
  justify-content: center;
  align-items: center;
  text-align: left;
}
.char-sheet-status-panel .equipment .armor-container .armor-name {
  grid-area: armor-name;
  align-self: flex-start;
  color: #e0e0e0;
}
.char-sheet-status-panel .equipment .armor-container .armor-class {
  grid-area: armor-type;
  color: #d61fb5;
}
.char-sheet-status-panel .equipment .armor-container .armor-ac {
  grid-area: ac;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  color: #aed7da;
}
.char-sheet-status-panel .equipment .armor-container .armor-ac span {
  width: 50px;
  margin-left: 1.85rem;
  border: solid 1px #4d4d4d;
  border-radius: 0.25rem;
  background: #268fa1;
  color: #aed7da;
  text-align: center;
}
.char-sheet-status-panel .equipment .armor-container .armor-bar {
  grid-area: bar;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  color: #aed7da;
}
.char-sheet-status-panel .equipment .armor-container .armor-bar span {
  width: 50px;
  margin-left: 1.2rem;
  border: solid 1px #4d4d4d;
  border-radius: 0.25rem;
  background: #4d4d4d;
  color: #aed7da;
  text-align: center;
}
.char-sheet-status-panel .equipment .armor-container .armor-cond {
  grid-area: cond;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  color: #aed7da;
}
.char-sheet-status-panel .equipment .armor-container .armor-cond span {
  width: 50px;
  margin-left: 0.48rem;
  border: solid 1px #4d4d4d;
  border-radius: 0.25rem;
  background: #121313;
  color: #aed7da;
  text-align: center;
}
.char-sheet-status-panel .equipment .armor-container .armor-effects {
  grid-area: effects;
  justify-content: center;
  align-items: center;
  text-align: center;
  height: 100%;
  padding: 0rem 1rem;
}
.char-sheet-status-panel .equipment .armor-container .armor-effects p {
  text-align: left;
}
.char-sheet-status-panel .equipment .armor-container .mods {
  grid-area: mods;
  justify-content: center;
  align-items: center;
  text-align: center;
  height: 100%;
}
.char-sheet-status-panel .equipment .armor-container .mods .mod-area {
  display: flex;
  padding: 0.25rem;
  justify-content: center;
}
.char-sheet-status-panel .equipment .armor-container .mods .mod {
  margin: 0 0.5rem;
  height: 30px;
  width: 30px;
  border: solid 1px #4d4d4d;
  border-radius: 50%;
}
.char-sheet-status-panel .equipment .armor-container .mods .equipped-mod {
  background: #ff8b1f;
}
.char-sheet-status-panel .equipment .weapon-container {
  position: relative;
  display: grid;
  margin: 0rem 0.5rem 0.5rem 0.5rem;
  padding: 0.5rem 1rem;
  grid-template-columns: 3fr 1fr 3fr;
  grid-template-areas:
    "weapon-name dd effects mods"
    "weapon-name range effects mods"
    "weapon-type cond effects mods";
  border: solid 1px #268fa1;
  justify-content: center;
  align-items: center;
  text-align: left;
}
.char-sheet-status-panel .equipment .weapon-container .reload-but {
  position: absolute;
  visibility: visible;
  right: 0;
  top: -20px;
  width: 80px;
  padding: 0.11rem 0;
  background: #268fa1;
  color: #e0e0e0;
  font-weight: bold;
  border: none;
  outline: none;
}
.char-sheet-status-panel .equipment .weapon-container .reload-but:hover {
  background: #aed7da;
  color: #0e201e;
}
.char-sheet-status-panel .equipment .weapon-container .weapon-name {
  grid-area: weapon-name;
  align-self: flex-start;
  color: #e0e0e0;
}
.char-sheet-status-panel .equipment .weapon-container .weapon-class {
  grid-area: weapon-type;
  color: #d61fb5;
}
.char-sheet-status-panel .equipment .weapon-container .weapon-dd {
  grid-area: dd;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  color: #aed7da;
}
.char-sheet-status-panel .equipment .weapon-container .weapon-dd span {
  width: 50px;
  margin-left: 1.85rem;
  border: solid 1px #4d4d4d;
  border-radius: 0.25rem;
  background: #268fa1;
  color: #aed7da;
  text-align: center;
}
.char-sheet-status-panel .equipment .weapon-container .weapon-range {
  grid-area: range;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  color: #aed7da;
}
.char-sheet-status-panel .equipment .weapon-container .weapon-range span {
  width: 50px;
  margin-left: 1.2rem;
  border: solid 1px #4d4d4d;
  border-radius: 0.25rem;
  background: #4d4d4d;
  color: #aed7da;
  text-align: center;
}
.char-sheet-status-panel .equipment .weapon-container .weapon-cond {
  grid-area: cond;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  color: #aed7da;
}
.char-sheet-status-panel .equipment .weapon-container .weapon-cond span {
  width: 50px;
  margin-left: 0.48rem;
  border: solid 1px #4d4d4d;
  border-radius: 0.25rem;
  background: #121313;
  color: #aed7da;
  text-align: center;
}
.char-sheet-status-panel .equipment .weapon-container .weapon-effects {
  grid-area: effects;
  justify-content: center;
  align-items: center;
  text-align: center;
  height: 100%;
  padding: 0rem 1rem;
}
.char-sheet-status-panel .equipment .weapon-container .weapon-effects p {
  text-align: left;
}
.char-sheet-status-panel .equipment .weapon-container .mods {
  grid-area: mods;
  justify-content: center;
  align-items: center;
  text-align: center;
  height: 100%;
}
.char-sheet-status-panel .equipment .weapon-container .mods .mod-area {
  display: flex;
  padding: 0.25rem;
  justify-content: center;
}
.char-sheet-status-panel .equipment .weapon-container .mods .mod {
  margin: 0 0.5rem;
  height: 30px;
  width: 30px;
  border: solid 1px #4d4d4d;
  border-radius: 50%;
}
.char-sheet-status-panel .equipment .weapon-container .mods .equipped-mod {
  background: #ff8b1f;
}
.char-sheet-status-panel .stims {
  grid-area: stims;
  justify-content: center;
  align-items: center;
  text-align: center;
  min-width: 500px;
}
.char-sheet-status-panel .stims .stim-type {
  text-align: left;
  margin-left: 10px;
  color: #ff8b1f;
}
.char-sheet-status-panel .stims .stim-container {
  position: relative;
  display: flex;
  flex-direction: row;
  margin: 0rem 0.5rem 0.5rem 0.5rem;
  border: solid 1px #268fa1;
  align-content: center;
}
.char-sheet-status-panel .stims .stim-container .recharge-but {
  position: absolute;
  visibility: visible;
  right: 0;
  top: -20px;
  width: 90px;
  padding: 0.11rem 0;
  background: #268fa1;
  color: #e0e0e0;
  font-weight: bold;
  border: none;
  outline: none;
}
.char-sheet-status-panel .stims .stim-container .recharge-but:hover {
  background: #aed7da;
  color: #0e201e;
}
.char-sheet-status-panel .stims .stim-container .stim-name {
  color: #e0e0e0;
  width: 200px;
  padding: 1.5rem 0rem;
  align-content: center;
}
.char-sheet-status-panel .stims .stim-container .charges {
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 100%;
  justify-content: space-evenly;
  align-items: center;
  padding: 1.5rem 0rem;
}
.char-sheet-status-panel .stims .stim-container .charges .charge-item {
  height: 20px;
  width: 20px;
  border: solid 1px gainsboro;
  transform: rotate(45deg);
  align-items: center;
}
.char-sheet-status-panel .stims .stim-container .charges .charged {
  background: #d61fb5;
  cursor: pointer;
}
.char-sheet-status-panel .status-items {
  grid-area: items;
  text-align: center;
  color: #2963f5;
  font-size: 1.5rem;
}
.char-sheet-status-panel .status-items > div:last-child {
  display: flex;
  flex-flow: row;
  justify-content: space-evenly;
  color: #ff8b1f;
}
.char-sheet-status-panel .status-items > div:last-child p {
  width: 80px;
  height: 40px;
  font-size: 1.5rem;
  line-height: 2.6rem;
  text-align: center;
  border: solid 1px #268fa1;
  border-radius: 30%;
  color: #e0e0e0;
  margin: auto;
}
.char-sheet-skill-panel {
  padding: 1rem;
}

.char-sheet-skill-panel .skill-top {
  display: flex;
  flex-direction: row;
  width: 100%;
  padding: 2rem;
  color: #aed7da;
}

.char-sheet-skill-panel .skill-top .skill-info {
  flex: 1 75%;
}

.char-sheet-skill-panel .skill-top .skill-points-container {
  flex: 1 25%;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
}

.char-sheet-skill-panel .skill-top .skill-points-container .skill-points-item {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.char-sheet-skill-panel
  .skill-top
  .skill-points-container
  .skill-points-item
  input {
  width: 50px;
  margin-left: 1rem;
  font-size: 1.5rem;
  text-align: center;
  border: solid 1px #268fa1;
  color: #ff8b1f;
}

.char-sheet-skill-panel .skill-header {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-end;
  text-align: center;
  width: 100%;
  padding: 0rem 0.5rem;
  font-size: 1.25rem;
  color: #2963f5;
}
.char-sheet-skill-panel .skill-header .skill-name {
  flex: 1 13%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.char-sheet-skill-panel .skill-header .skill-mod {
  flex: 1 8%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.char-sheet-skill-panel .skill-header .skill-att {
  flex: 1 7%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.char-sheet-skill-panel .skill-header .skill-level {
  flex: 1 12%;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: right;
}
.char-sheet-skill-panel .skill-header .skill-level-chart {
  flex: 10 50%;
}
.char-sheet-skill-panel .skill-header .expertise-container {
  flex: 1 10%;
  margin-right: 1.5rem;
}

.char-sheet-skill-panel .skill-row {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  border: solid 1px #268fa1;
  width: 100%;
  margin-bottom: 0.5rem;
  padding: 0.25rem 0.5rem;
}

.char-sheet-skill-panel .skill-row .skill-subcontainer {
  flex: 1 50%;
  width: 100%;
  display: flex;
  flex-direction: row;
}

.char-sheet-skill-panel .skill-row .skill-name {
  flex: 1 25%;
  font-size: 1.2rem;
  line-height: 1.2rem;
  color: #e0e0e0;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  text-align: left;
  overflow-wrap: break-word;
    hyphens: manual;
}
.char-sheet-skill-panel .skill-row .skill-mod {
  flex: 1 50px;
  font-size: 1rem;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}
.char-sheet-skill-panel .skill-row .skill-mod div {
  border: solid 1px #268fa1;
  color: #ff8b1f;
  border-radius: 50%;
  width: 32px;
  padding: 2px 4px;
  text-align: center;
}
.char-sheet-skill-panel .skill-row .skill-att {
  flex: 1 50px;
  font-size: 1.25rem;
  color: #4d4d4d;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}
.char-sheet-skill-panel .skill-row .skill-level {
  flex: 1 100px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
.char-sheet-skill-panel .skill-row .skill-level .skill-level-value {
  font-size: 1.25rem;
  color: #e0e0e0;
  border: solid 1px #4d4d4d;
  background: #e0e0e0;
  color: #121313;
  border-radius: 20%;
  text-align: center;
  width: 35px;
  height: 35px;
  margin-left: 0.5rem;
}
.char-sheet-skill-panel .skill-row .skill-level button {
  height: 25px;
  width: 25px;
  background: #ff8b1f;
  margin: 0 5px;
  border-radius: 20%;
}
.char-sheet-skill-panel .skill-row .skill-level-chart {
  flex: 10 500px;
  font-size: 1.5rem;
  color: #2963f5;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-evenly;
  padding: 0rem 1rem;
}
.char-sheet-skill-panel .skill-row .skill-level-chart .perk {
  width: 12px;
  height: 20px;
  border: solid 1px #4d4d4d;
  border-radius: 20%;
  background-color: none;
  margin-right: 5px;
}
.char-sheet-skill-panel .skill-row .skill-level-chart .skill-mark {
  width: 5px;
  height: 20px;
  border: solid 1px #4d4d4d;
  border-radius: 20%;
  background-color: none;
  margin-right: 5px;
}
.char-sheet-skill-panel .skill-row .skill-level-chart .filled {
  background: #d61fb5;
}
.char-sheet-skill-panel .skill-row .expertise_container {
  flex: 2 120px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.expertise_select {
  color: #aed7da;
  font-size: 1rem;
  line-height: 1.2rem;
  padding: 0.5rem;
  text-align: center;
  background: #4d4d4d;
  border: solid 1px #268fa1;
  height: 40px;
  width: 120px;
  -o-appearance: none;
  -ms-appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  cursor: pointer;
}

.char-sheet-actions-panel {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 1rem;
  border: solid 1px #0e201e;
  color: #e0e0e0;
}
.char-sheet-actions-panel h1 {
  width: 100%;
  text-align: left;
  font-size: 1.25rem;
  margin-top: 1rem;
}
.char-sheet-actions-panel div {
  display: flex;
  flex-direction: row;
  justify-items: flex-start;
  align-items: center;
  width: 100%;
}

.char-sheet-actions-panel .attackModalButtonContainer {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
}

.char-sheet-actions-panel .attackModalButtonContainer .attackModalButton {
  display: flex;
  justify-content: center;
  text-align: center;
  width: 200px;
  background: #268fa1;
  color: #121313;
  margin: 1rem;
  padding: 0.5rem 0rem;
  border: solid 1px #aed7da;
  border-radius: 0.5rem;
  cursor: pointer;
  font-weight: bold;
}

.char-sheet-actions-panel .attackModalButtonContainer .attackModalButton:hover {
  background: orange;
}

.char-sheet-actions-panel .attackModalButtonContainer .attackModalButton:active {
  background: #56c4d7;
}

.char-sheet-actions-panel .hand {
  margin-bottom: -1rem;
  margin-top: 1rem;
  color:#d61fb5;
}

.char-sheet-actions-panel
  .equipContainer {
  display: flex;
  flex-direction: row;
  text-align: center;
  width: auto;
  min-width: 67%;
  border: solid 1px #268fa1;
  padding: 1rem 2rem;
  font-size: 1rem;
  margin: 1rem;
}

.char-sheet-actions-panel .equipContainer h2 {
  font-size: 1.2rem;
  flex: 1 20%;
  color: #ff8b1f;
}

.char-sheet-actions-panel .equipContainer div {
    flex: 33%;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    color:#aed7da;
}

.char-sheet-actions-panel .equipContainer div p {
  margin-right: 1rem;
}

.char-sheet-actions-panel .equipContainer div span {
  color: #e0e0e0;
}

.char-sheet-actions-panel h1 {
  font-size: 1.5rem;
  color:#2963f5;
}

.char-sheet-actions-panel .action-container {
  margin-top: 0.5rem;
}

.char-sheet-actions-panel .action-container .action-name {
  flex: 2 200px;
  color: #ff8b1f;
  font-size: 1rem;
  padding: 0.5rem;
  margin-right: 1rem;
}
.char-sheet-actions-panel .action-container .action-desc {
  flex: 1 85%;
  font-size: 0.8rem;
  padding: 0.5rem;
}

.attackModal {
  background: #121313;
  color: #aed7da;
  position: absolute;
  top: 100px;
  left: 20%;
  right: 20%;
  max-height: 100%;
  border: solid 2px #268fa1;
  display: flex;
  flex-direction: row;
}

.attackOverlay {
  position: fixed;
  padding: 0;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(49, 49, 49, 0.6);
}

.attackModal .attackPanel {
  flex: 1 75%;
  display: flex;
  flex-direction: column;
  overflow: auto;
}

.attackModal .attackPanel .attackPanelTop {
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}

.attackModal .attackPanel .attackPanelTop .sitchButton {
  display: none;
  
}

.attackModal .attackPanel .attackPanelTop .closeAttackModal {
  position:absolute;
  top: 0;
  right: 0;
  background: #d42323;
  color:#e0e0e0;
  padding: 0.5rem;
  text-align: center;
}

.attackModal .attackPanel .attackTabContainer {
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-evenly;
  border-bottom: solid 1px #aed7da;
}

.attackModal .attackPanel .attackTabContainer div {
  background:  #0e201e;
  color: #ff8b1f;
  border: solid 1px #0e201e;
  width: 100%;
  text-align: center;
  cursor: pointer;
}

.attackModal .attackPanel .attackTabContainer div:hover {
  background: #268fa1;
  color: #aed7da;
}

.attackModal .attackPanel .attackTabContainer .selected {
  background: #ff8b1f;
  color: #121313;
}

.attackModal .attackPanel .hidden {
  display: none;
}

.attackModal .attackPanel .attackModalWrapper {
  display: flex;
  flex-direction: column;
  padding: 1rem;
}

.attackModal .attackPanel .attackModalWrapper .attackModalRow {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-evenly;
  margin: 1rem 0rem;
}

.attackModal .attackPanel .attackModalWrapper .attackModalRow > div {
  display: flex;
  flex-direction: row;
}

.attackModal .attackPanel .attackModalWrapper .attackModalRow input {
  border: solid 1px #ff8b1f;
  border-radius: 0.5rem;
  padding: 0.5rem;
  margin: 0rem 1rem;
  text-align: center;
  font-size: 1.2rem;
}

.attackModal .attackPanel .attackModalWrapper .attackModalRow .greenBord {
  border: solid 1px #33ab6b;
}

.attackModal .attackPanel .attackModalWrapper .attackModalRow .redBord {
  border: solid 1px #d42323;
}

.attackModal .attackPanel .attackModalWrapper .attackModalRow p {
  width: 60px;
  text-align: center;
  border: solid 1px #7b7b7b;
  padding: 0.5rem;
  margin: 0rem 1rem;
  border-radius: 0.5rem;
  color: #7b7b7b;
}

.attackModal .attackPanel .attackModalWrapper .attackModalRow .result {
  width: 60px;
  text-align: center;
  border: solid 1px #aed7da;
  padding: 0.5rem;
  margin: 0rem 1rem;
  border-radius: 0.5rem;
  font-weight: bold;
  color: #aed7da;
}

.attackModal .attackPanel .attackModalWrapper .hitButs{
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-evenly;
  margin: 1rem 0rem;
}

.attackModal .attackPanel .attackModalWrapper .hitButs .hitBut {
  width: 120px;
  padding: 0.5rem;
  text-align: center;
  border: solid 1px #aed7da;
  border-radius: 0.5rem;
  cursor: pointer;
}

.attackModal .attackPanel .attackModalWrapper .hitButs .hit {
  background: #2963f5;
}

.attackModal .attackPanel .attackModalWrapper .hitButs .miss {
  background: #0e201e;
}

.attackModal .attackPanel .attackModalWrapper .hitButs .hitBut:hover {
  background: #aed7da;
  color: #121313;
}

.attackModal .attackPanel .attackModalWrapper .attackModalRow .missRow {
  margin-top: 1rem;
  color:#4d4d4d;
  font-size: 1.5rem;
}

.attackModal .attackPanel .attackModalWrapper .attackModalTotalRow {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 1rem;
  border-top: solid 2px #2963f5;
  margin-top: 1rem;
  text-align: center;
  font-size: 1rem;
}

.attackModal .attackPanel .attackModalWrapper .blunt {
  border-top: none;
  margin-top: -1rem;
}

.attackModal .attackPanel .attackModalWrapper .attackModalTotalRow p {
  margin-right: 1rem;
}

.attackModal .attackPanel .attackModalWrapper .attackModalTotalRow p:last-of-type {
  color:#2963f5;
  margin-left: 1rem;
}

.attackModal .attackPanel .totalDamageRow {
  justify-self: flex-end;
  align-self: flex-end;
  width: 100%;
  padding: 0.5rem;
  background: #0e201e;
  color: #ff8b1f;
  font-weight: bold;
  text-align: center;
  font-size: 1.8rem;
  border-top: solid 1px #aed7da;
}

.attackModal .closed {
  opacity: 0;
  z-index: -1;
}

.attackModal .open {
  opacity: 1;
  z-index: 1;
}

.attackModal .sitchPanel{
  flex: 1 25%;
  display: flex;
  background: #121313;
  color: #eee;
  overflow: auto;
  padding: 1rem;
  flex-direction: column;
  border-left: solid 1px #aed7da;
}

.attackModal .sitchPanel h2 {
  text-align: center;
  font-weight: bold;
  margin-bottom: 1rem;
  color:#aed7da
}

.attackModal .sitchPanel .sitch {
  text-align: center;
  padding: 0.2rem 0.5rem;
  background: #0e201e;
  color:#aed7da;
  cursor: pointer;
  border: solid 1px #4d4d4d;
  border-radius: 0.5rem;
  margin-bottom: 0.25rem;
}

.attackModal .sitchPanel .advSitch {
  text-align: center;
  padding: 0.2rem 0.5rem;
  background: #1c5e3b;
  color:#aed7da;
  cursor: pointer;
  border: solid 1px #4d4d4d;
  border-radius: 0.5rem;
  margin-bottom: 0.25rem;
}

.attackModal .sitchPanel .disadvSitch {
  text-align: center;
  padding: 0.2rem 0.5rem;
  background: #30060f;
  color:#aed7da;
  cursor: pointer;
  border: solid 1px #4d4d4d;
  border-radius: 0.5rem;
  margin-bottom: 0.25rem;
}

.attackModal .sitchPanel .sitch:hover{
  background: #268fa1;
  color:#121313;
}

.attackModal .sitchPanel .advSitch:hover{
  background: #33ab6b;
  color:#121313;
}

.attackModal .sitchPanel .disadvSitch:hover{
  background: #d42323;
  color:#121313;
}

.attackModal .sitchPanel .active {
  border: solid 3px #ff8b1f;
}

.lvlUpModal {
  background: #121313;
  color: #aed7da;
  position: absolute;
  top: 10%;
  left: calc(50% - 350px);
  width: 700px;
  border: solid 2px #268fa1;
}

.lvlUpOverlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(49, 49, 49, 0.6);
}

.lvlUpModal .levelUpWrapper {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  height: 100%;
  padding: 1rem;
}

.lvlUpModal .levelUpWrapper .closeLvlUpModal {
  position: absolute;
  top: 0;
  right: 0;
  background: #d42323;
  color:#e0e0e0;
  padding: 0.5rem;
  text-align: center;
}

.lvlUpModal h1 {
  color: #ff8b1f;
  text-align: center;
}

.lvlUpModal .levelUpItem {
  display: flex;
  flex-direction: row;
}

.lvlUpModal .levelUpItem h2 {
  color: #268fa1;
  margin: 2rem 2rem;
}

.lvlUpModal .levelUpItem p {
  margin: 2rem 2rem;
  font-size: 1.5rem;
}

.lvlUpModal .levelUpAttrItem {
  display: flex;
  flex-direction: column;
}

.lvlUpModal .levelUpAttrItem h2 {
  color: #268fa1;
  margin: 2rem 2rem;
}

.lvlUpModal .levelUpAttrItem .levelUpAttrContainer {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 1rem;
  margin-bottom: 2rem;

}

.lvlUpModal .levelUpAttrItem .levelUpAttrContainer p {
  background: #0e201e;
  color: #aed7da;
  font-size: 1.2rem;
  border: 1px solid #56c4d7;
  border-radius: 0.5rem;
  width: 60px;
  text-align: center;
  margin: 0.5rem 0.5rem;
  cursor: pointer;
}

.lvlUpModal .levelUpAttrItem .levelUpAttrContainer p:hover {
  background: #aed7da;
  color: #0e201e;
}

.lvlUpModal .levelUpAttrItem .levelUpAttrContainer .selected {
  background: #ff8b1f;
  color: #0e201e;
}

.lvlUpModal .levelUpAttrItem .attrChangeInfo {
  text-align: center;
  margin: 0.5rem;
}

.lvlUpModal .levelUpAttrItem .attrChangeInfo span:first-of-type {
  color: #268fa1;
}

.lvlUpModal .levelUpConfirm {
  width: 200px;
  background: #ff8b1f;
  color: #121313;
  font-size: 1.2rem;
  font-weight: bold;
  border: solid 1px #aed7da;
  border-radius: 0.5rem;
  text-align: center;
  padding: 0.5rem;
  cursor: pointer;
}

.lvlUpModal .levelUpConfirm:hover {
  background: #aed7da;
  color: #121313;
}

.edit-char-main-panel {
  display: flex;
  flex-direction: column;
  padding: 1rem;
  border: solid 1px #0e201e;
  color: #e0e0e0;
}

.edit-char-main-panel .first-row {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  margin-bottom: 1rem;
}

.edit-char-main-panel .first-row .vitals {
  flex: 1 33%;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}
.edit-char-main-panel .first-row .vitals div {
  width: 350px;
  display: flex;
  flex-direction: row;
  margin-top: 0.5rem;
  align-items: center;
  justify-content: flex-end;
  margin-right: 1rem;
}
.edit-char-main-panel .first-row .vitals div .vital-container {
  width: 200px;
  padding: 4px 10px;
  display: block;
  margin-left: 1rem;
  margin-bottom: 0.5rem;
  border: solid 1px #268fa1;
  border-radius: 0.25rem;
  font-size: 1.1rem;
}
.edit-char-main-panel .first-row .vitals div .vital-select {
  width: 200px;
  padding: 4px 10px;
  display: block;
  margin-left: 1rem;
  margin-bottom: 0.5rem;
  border: solid 1px #268fa1;
  border-radius: 0.25rem;
  font-size: 1.1rem;
  background: #4d4d4d;
  color: #e0e0e0;
}

.edit-char-main-panel .first-row .attributes {
  flex: 1 67%;
  text-align: center;
  color: #2963f5;
  font-size: 1.5rem;
  
}

.edit-char-main-panel .first-row .attributes .attribute-container {
  display: flex;
  justify-content: center;
  text-align: center;
  align-items: center;
  color: #ff8b1f;
}

.edit-char-main-panel .first-row .attributes .attribute-container .attribute-item {
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  align-items: center;
  color: #ff8b1f;
  margin: 0% 2%;
}

.edit-char-main-panel .first-row .attributes .attribute-container .attribute-item input {
  width: 60px;
  height: 60px;
  font-size: 2rem;
  line-height: 3.6rem;
  text-align: center;
  border: solid 1px #268fa1;
  border-radius: 30%;
  color: #e0e0e0;
}

.edit-char-main-panel .first-row .attributes .attribute-container .attribute-item .att-mod {
  position: relative;
  width: 40px;
  padding: 2px 0;
  background: #aed7da;
  border: 1px solid #268fa1;
  color: #121313;
  text-align: center;
  font-size: 1.25rem;
  border-radius: 50%;
  margin: auto;
  top: -10px;
}

.edit-char-main-panel .second-row {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  margin-bottom: 1rem;
}

.edit-char-main-panel .languages {
  flex: 1 50%;
  padding: 0rem 1rem;
}
.edit-char-main-panel .languages .lang-container {
  display: block;
  padding: 1rem;
  margin: 0rem 1rem;
  border: solid 1px #268fa1;
  border-radius: 0.25rem;
}
.edit-char-main-panel .other-stats {
  flex: 1 50%;
  text-align: center;
  color: #2963f5;
  font-size: 1.5rem;
}
.edit-char-main-panel .other-stats > div:last-child {
  display: flex;
  flex-flow: row;
  justify-content: space-evenly;
  color: #ff8b1f;
}
.edit-char-main-panel .other-stats > div:last-child p {
  width: 80px;
  height: 40px;
  font-size: 1.5rem;
  line-height: 2.6rem;
  text-align: center;
  border: solid 1px #268fa1;
  border-radius: 30%;
  color: #e0e0e0;
  margin: auto;
}
.edit-char-main-panel .info-container {
  display: block;
  padding: 1rem;
  margin: 0rem 1rem;
  border: solid 1px #268fa1;
  border-radius: 0.25rem;
}
.edit-char-main-panel .personality {
  grid-area: personality;
}
.edit-char-main-panel .personality textarea {
  width: 95%;
}
.edit-char-main-panel .motivation {
  grid-area: motivation;
}
.edit-char-main-panel .motivation textarea {
  width: 95%;
}
.edit-char-main-panel .fears {
  grid-area: fears;
}
.edit-char-main-panel .fears textarea {
  width: 95%;
}
.edit-char-main-panel .backstory {
  grid-area: backstory;
}
.edit-char-main-panel .backstory textarea {
  width: 97.5%;
}
.edit-char-status-panel {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 1rem;
  border: solid 1px #0e201e;
  color: #e0e0e0;
}
.edit-char-status-panel .health-container {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
  text-align: center;
}

.edit-char-status-panel .health-subcontainer {
  flex: 1 50%;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
  text-align: center;
}

.edit-char-status-panel .health-border {
  border: solid 1px #268fa1;
  width: 300px;
  height: 40px;
  margin: 0.5rem 0;
}
.edit-char-status-panel .health-border input {
  background: none;
  padding: 0.2rem;
  width: 40px;
  height: 100%;
  background: #4d4d4d;
  font-size: 1.25rem;
  margin: 0 0.5rem;
  text-align: center;
}

.edit-char-status-panel .health-border span {
  background: none;
  padding: 0.2rem;
  width: 40px;
  height: 100%;
  font-size: 1.25rem;
  margin: 0 0.5rem;
  text-align: center;
}

.edit-char-status-panel .health-fill {
  position: absolute;
  background: #ff8b1f;
  height: 38px;
  width: 0px;
  transform: translate(0px, 9px);
  z-index: -1;
}
.edit-char-status-panel .dis-fill {
  position: absolute;
  background: #268fa1;
  height: 38px;
  width: 0px;
  transform: translate(0px, 9px);
  z-index: -1;
}
.edit-char-status-panel .kin-fill {
  position: absolute;
  background: #8eb0b3;
  height: 38px;
  width: 0px;
  transform: translate(0px, 9px);
  z-index: -1;
}

.edit-char-status-panel .targ-eva {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
}

.edit-char-status-panel .targ-eva > div:first-of-type {
  margin-right: 1.5rem;
}

.edit-char-status-panel .ac input {
  width: 60px;
  height: 60px;
  font-size: 2rem;
  line-height: 3.6rem;
  text-align: center;
  border: solid 1px #268fa1;
  border-radius: 30%;
  color: #e0e0e0;
}
.edit-char-status-panel .status {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  text-align: center;
}

.edit-char-status-panel .status-subcontainer {
  flex: 1 50%;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  text-align: center;
}

.edit-char-status-panel .status-subcontainer .status-item {
  display: flex;
  flex-direction: column;
  min-height: 150px;
  width: 250px;
  margin: 1rem;
  border: solid 1px #268fa1;
}

.edit-char-status-panel .status-subcontainer .status-item p {
  width: 100%;
  background: #0e201e;
  color: #ff8b1f;
  border-bottom: solid 1px #4d4d4d;
}

.edit-char-status-panel .status-subcontainer .status-item div {
  padding: 0rem;
  background: #aed7da;
  color: #121313;
  border-bottom: solid 1px #4d4d4d;
  height: 30px;
  width: 100%;
}

.edit-char-status-panel .status-subcontainer .status-item div:hover {
  background: #d61fb5;
  cursor: pointer;
}

.edit-char-status-panel .status-subcontainer .status-item select {
  margin-top: auto;
  align-self: flex-end;
  background: #ff8b1f;
  height: 30px;
  width: 100%;
  max-height: 180px;
}

.edit-char-status-panel .equipment-stims {
  display: flex;
  flex-direction: row;
  width: 100%;
}

.edit-char-status-panel .equipment-stims .equipment {
  flex: 66%;
  justify-content: center;
  align-items: center;
  text-align: center;
  
}
.edit-char-status-panel .equipment-stims .equipment .equip-type {
  text-align: left;
  margin-left: 10px;
  color: #ff8b1f;
}
.edit-char-status-panel .equipment-stims .equipment .armor-container {
  display: flex;
  flex-direction: row;
  margin: 0rem 0.5rem 0.5rem 0.5rem;
  padding: 0.5rem 0.5rem;
  border: solid 1px #268fa1;
  justify-content: center;
  align-items: center;
  text-align: left;
}

.edit-char-status-panel .equipment-stims .equipment .armor-subcontainer {
  flex: 1 25%;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  width: 100%;
}

.edit-char-status-panel .equipment-stims .equipment .armor-subcontainer:first-of-type {
  flex: 1 50%;
}

.edit-char-status-panel .equipment-stims .equipment .armor-container .armor-subcontainer  .armor-name {
  flex: 1 66%;
  align-self: flex-start;
  color: #e0e0e0;
  padding: 0.5rem;
  margin-right: 1rem;
}
.edit-char-status-panel .equipment-stims .equipment .armor-container .armor-subcontainer  .armor-class {
  color: #d61fb5;
  padding: 0.5rem;
  margin-right: 1rem;
}
.edit-char-status-panel .equipment-stims .equipment .armor-container .armor-subcontainer  .armor-dis {
  display: flex;
  flex-direction: row;
  align-items: center;
  color: #aed7da;
  margin: 0.25rem;
}
.edit-char-status-panel .equipment-stims .equipment .armor-container .armor-subcontainer  .armor-dis span {
  width: 50px;
  height: 25px;
  margin-left: 1rem;
  border: solid 1px #4d4d4d;
  border-radius: 0.25rem;
  background: #268fa1;
  color: #aed7da;
  text-align: center;
}
.edit-char-status-panel .equipment-stims .equipment .armor-container .armor-subcontainer  .armor-kin {
  display: flex;
  flex-direction: row;
  align-items: center;
  color: #aed7da;
  margin: 0.25rem;
}
.edit-char-status-panel .equipment-stims .equipment .armor-container .armor-subcontainer  .armor-kin span {
  width: 50px;
  height: 25px;
  margin-left: 1rem;
  border: solid 1px #4d4d4d;
  border-radius: 0.25rem;
  background: #aed7da;
  color: #0e201e;
  text-align: center;
}
.edit-char-status-panel .equipment-stims .equipment .armor-container .armor-subcontainer  .armor-cond {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  color: #aed7da;
  margin:0.25rem;
}
.edit-char-status-panel .equipment-stims .equipment .armor-container .armor-subcontainer  .armor-cond span {
  width: 50px;
  height: 25px;
  margin-left: 1rem;
  border: solid 1px #4d4d4d;
  border-radius: 0.25rem;
  background: #121313;
  color: #aed7da;
  text-align: center;
}
.edit-char-status-panel .equipment-stims .equipment .armor-container .armor-subcontainer  .armor-effects {
  grid-area: effects;
  justify-content: center;
  align-items: center;
  text-align: center;
  height: 100%;
  padding: 0rem 0.5rem;
}
.edit-char-status-panel .equipment-stims .equipment .armor-container .armor-subcontainer  .armor-effects textarea {
  text-align: left;
  padding: 0.5rem;
  width: 100%;
}
.edit-char-status-panel .equipment-stims .equipment .armor-container  .mods {
  flex: 1 25%;
  justify-content: center;
  align-items: center;
  text-align: center;
  width: 100%;
  height: 100%;
}
.edit-char-status-panel .equipment-stims .equipment .armor-container .mods .mod-area {
  display: flex;
  padding: 0.25rem 0rem;
  justify-content: center;
}
.edit-char-status-panel .equipment-stims .equipment .armor-container .mods .mod {
  margin: 0 0.5rem;
  height: 30px;
  width: 30px;
  border: solid 1px #4d4d4d;
  border-radius: 50%;
}
.edit-char-status-panel .equipment-stims .equipment .armor-container .mods .equipped-mod {
  background: #ff8b1f;
}

.edit-char-status-panel .equipment-stims .equipment .weapon-container {
  display: flex;
  flex-direction: row;
  margin: 0rem 0.5rem 0.5rem 0.5rem;
  padding: 0.5rem 0.5rem;
  border: solid 1px #268fa1;
  justify-content: center;
  align-items: center;
  text-align: left;
}

.edit-char-status-panel .equipment-stims .equipment .weapon-container .reload-but {
  position: absolute;
  visibility: visible;
  right: 0;
  top: -20px;
  width: 80px;
  padding: 0.11rem 0;
  background: #268fa1;
  color: #e0e0e0;
  font-weight: bold;
  border: none;
  outline: none;
  cursor: pointer;
}

.edit-char-status-panel .equipment-stims .equipment .weapon-container .attack-but {
  position: absolute;
  visibility: visible;
  right: 80px;
  top: -20px;
  width: 80px;
  padding: 0.11rem 0;
  background: #ff8b1f;
  color: #e0e0e0;
  font-weight: bold;
  border: none;
  outline: none;
  cursor: pointer;
}

.edit-char-status-panel .equipment-stims .equipment .weapon-container .reload-but:hover {
  background: #aed7da;
  color: #0e201e;
}

.edit-char-status-panel .equipment-stims .equipment .weapon-container .attack-but:hover {
  background: #aed7da;
  color: #0e201e;
}

.edit-char-status-panel .equipment-stims .equipment .weapon-subcontainer {
  flex: 1 25%;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  width: 100%;
}

.edit-char-status-panel .equipment-stims .equipment .weapon-subcontainer:first-of-type {
  flex: 1 50%;
}

.edit-char-status-panel .equipment-stims .equipment .weapon-container .weapon-subcontainer .weapon-name {
  flex: 1 66%;
  align-self: flex-start;
  color: #e0e0e0;
  padding: 0.5rem;
  margin-right: 1rem;
}
.edit-char-status-panel .equipment-stims .equipment .weapon-container .weapon-subcontainer .weapon-class {
  color: #d61fb5;
  padding: 0.5rem;
  margin-right: 1rem;
}
.edit-char-status-panel .equipment-stims .equipment .weapon-container .weapon-subcontainer .weapon-dd {
  grid-area: dd;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  color: #aed7da;
  margin: 0.25rem;
}
.edit-char-status-panel .equipment-stims .equipment .weapon-container .weapon-subcontainer .weapon-dd span {
  width: 50px;
  height: 25px;
  margin-left: 1.85rem;
  border: solid 1px #4d4d4d;
  border-radius: 0.25rem;
  background: #268fa1;
  color: #aed7da;
  text-align: center;
}
.edit-char-status-panel .equipment-stims .equipment .weapon-container .weapon-subcontainer .weapon-range {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  color: #aed7da;
  margin: 0.25rem;
}
.edit-char-status-panel .equipment-stims .equipment .weapon-container .weapon-subcontainer .weapon-range span {
  width: 50px;
  height: 25px;
  margin-left: 1.2rem;
  border: solid 1px #4d4d4d;
  border-radius: 0.25rem;
  background: #aed7da;
  color: #0e201e;
  text-align: center;
}
.edit-char-status-panel .equipment-stims .equipment .weapon-container .weapon-subcontainer .weapon-cond {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  color: #aed7da;
  margin: 0.25rem;
}
.edit-char-status-panel .equipment-stims .equipment .weapon-container .weapon-subcontainer .weapon-cond span {
  width: 30px;
  height: 25px;
  margin-left: 0.48rem;
  border: solid 1px #4d4d4d;
  border-radius: 0.25rem;
  background: #121313;
  color: #aed7da;
  text-align: center;
}
.edit-char-status-panel .equipment-stims .equipment .weapon-container .weapon-subcontainer .weapon-effects {
  justify-content: center;
  align-items: center;
  text-align: center;
  width: 100%;
  height: 100%;
  padding: 0rem 1rem;
}
.edit-char-status-panel .equipment-stims .equipment .weapon-container .weapon-subcontainer .weapon-effects textarea {
  text-align: left;
  width: 100%;
  padding: 0.5rem;
}
.edit-char-status-panel .equipment-stims .equipment .weapon-container .mods {
  flex: 1 25%;
  justify-content: center;
  align-items: center;
  text-align: center;
  height: 100%;
}
.edit-char-status-panel .equipment-stims .equipment .weapon-container .mods .mod-area {
  display: flex;
  padding: 0.25rem;
  justify-content: center;
}
.edit-char-status-panel .equipment-stims .equipment .weapon-container .mods .mod {
  margin: 0 0.5rem;
  height: 30px;
  width: 30px;
  border: solid 1px #4d4d4d;
  border-radius: 50%;
}
.edit-char-status-panel .equipment-stims .equipment .weapon-container .mods .equipped-mod {
  background: #ff8b1f;
}

.edit-char-status-panel .equipment-stims .stim-wrapper {
  flex: 1 50%;
  display: flex;
  flex-direction: column;
}

.edit-char-status-panel .equipment-stims .stim-wrapper .stims {
  justify-content: center;
  align-items: center;
  text-align: center;
}
.edit-char-status-panel .equipment-stims .stim-wrapper .stims .stim-type {
  text-align: left;
  color: #ff8b1f;
}
.edit-char-status-panel .equipment-stims .stim-wrapper .stims .stim-container {
  position: relative;
  display: flex;
  flex-direction: row;
  margin: 0rem 0.5rem 0.5rem 0.5rem;
  border: solid 1px #268fa1;
  align-content: center;
  justify-content: space-evenly;
}
.edit-char-status-panel .equipment-stims .stim-wrapper .stims .stim-container .recharge-but {
  position: absolute;
  visibility: visible;
  right: 0;
  top: -20px;
  width: 90px;
  padding: 0.11rem 0;
  background: #268fa1;
  color: #e0e0e0;
  font-weight: bold;
  border: none;
  outline: none;
  cursor: pointer;
}
.edit-char-status-panel .equipment-stims .stim-wrapper .stims .stim-container .recharge-but:hover {
  background: #aed7da;
  color: #0e201e;
}
.edit-char-status-panel .equipment-stims .stim-wrapper .stims .stim-container .stim-name {
  flex: 1 20%;
  color: #ff8b1f;
  max-width: 200px;
  padding: 1.5rem 1rem;
  align-content: center;
}
.edit-char-status-panel .equipment-stims .stim-wrapper .stims .stim-container .stim-name select {
  background:#0e201e;
  color: #e0e0e0;
  font-size: 1.2rem;
  padding: 0.5rem;
}
.edit-char-status-panel .equipment-stims .stim-wrapper .stims .stim-container .charges {
  flex: 1 80%;
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 100%;
  justify-content: space-evenly;
  align-items: center;
  padding: 1.8rem 0rem;
}
.edit-char-status-panel .equipment-stims .stim-wrapper .stims .stim-container .charges .charge-item {
  height: 20px;
  width: 20px;
  border: solid 1px gainsboro;
  transform: rotate(45deg);
  align-items: center;
  margin-right: 0.5rem;
}
.edit-char-status-panel .equipment-stims .stim-wrapper .stims .stim-container .charges .charged {
  background: #d61fb5;
  cursor: pointer;
}
.edit-char-status-panel .equipment-stims .stim-wrapper .status-items {
  grid-area: items;
  text-align: center;
  color: #2963f5;
  font-size: 1rem;
}
.edit-char-status-panel .equipment-stims .stim-wrapper .status-items > div:last-child {
  display: flex;
  flex-flow: row;
  justify-content: space-evenly;
  color: #ff8b1f;
}

.edit-char-status-panel .equipment-stims .stim-wrapper .status-items > div:last-child p {
  width: 80px;
  height: 40px;
  font-size: 1.5rem;
  line-height: 2.6rem;
  text-align: center;
  border: solid 1px #268fa1;
  border-radius: 30%;
  color: #e0e0e0;
  margin: auto;
}

.creation-menu {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
  text-align: center;
  height: 40px;
  background: #0e201e;
  margin-bottom: 1rem;
  font-size: 1.2rem;
}
.creation-menu-tab {
  display: flex;
  justify-content: center;
  align-items: center;
  background: none;
  color: #aed7da;
  font-size: 1.25rem;
  height: 100%;
  width: 100%;
}
.creation-menu-tab:hover {
  background: #aed7da;
  color: #0e201e;
  cursor: pointer;
}
.creation-menu-tab .active-tab {
  background: #ff8b1f;
  color: #0e201e;
}

.char-creation-footer {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  text-align: center;
  align-items: center;
  padding: 2rem;
  margin-top: 2rem;
  background: #0e201e;
}

.char-creation-footer button {
  padding: 0.5rem;
  width: 150px;
  font-size: 1.2rem;
  border: solid 1px #4d4d4d;
  border-radius: 10px;
  outline: none;
  cursor: pointer;
  background: #aed7da;
  color: #121313;
}

.char-creation-footer .submit {
  width: 250px;
  background: #ff8b1f;
  color: #121313;
}

.speciesListContainer {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 2rem;
}

.speciesListContainer .speciesListItem {
  display: flex;
  flex-direction: row;
  align-items: center;
  background:#444747;
  padding: 1rem;
  border: solid 1px #0e201e;
  margin-bottom: 2rem;
  width: 80%;
  max-width: 1200px;
  border-radius: 1rem;
  align-self: center;
}

.speciesListContainer .speciesListItem h1 {
  flex: 1 15%;
  color:#ff8b1f;
  margin-right: 2rem;
  text-align: center;
}

.speciesListContainer .speciesListItem p {
  flex: 1 85%;
  color:#aed7da; 
}

.speciesInfoContainer h2{
  color:#2963f5;
  margin-top: 1rem;
}

.char-creation-race-panel {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.char-creation-race-panel .race-list {
  display: flex;
  flex-direction: row;
  width: 80%;
  justify-content: space-evenly;
  margin-top: 2rem;
}
.race-list > * {
  display: flex;
  height: 80px;
  width: 100%;
  background: #444747;
  color: #121313;
  font-size: 1.5rem;
  border: solid 1px #0e201e;
  text-align: center;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}
.race-list > *:hover {
  background: #aed7da;
  color: #0e201e;
}
.race-list .selected {
  background: #2963f5;
  color: #121313;
}
.race-panel {
  flex-direction: column;
  width: 80%;
  color: #aed7da;
  background: #0e201e;
}

.race-panel div {
  display: flex;
  flex-direction: row;
  width: 100%;
  padding: 1rem 2rem;
  margin: 1rem 0rem;
  align-items: center;
}

.char-creation-race-panel .race-panel div h2 {
  flex: 1 20%;
  color: #ff8b1f;
}
.char-creation-race-panel .race-panel div p {
  flex: 1 80%;
}

.char-creation-race-panel .attribute-buttons {
  justify-content: center;
}

.char-creation-race-panel .attribute-buttons button {
  padding: 0.5rem 1.5rem;
  font-size: 1.2rem;
  margin: 0rem 1rem;
  cursor: pointer;
}

.char-creation-race-panel .attribute-buttons .unselected {
  background: #4d4d4d;
}

.char-creation-race-panel .attribute-buttons .selected {
  background: #ff8b1f;
}

.char-creation-race-panel .race-panel .select {
  margin: auto;
  padding: 0.7rem 2rem;
  font-size: 1.2rem;
  font-weight: bold;
  background: #aed7da;
  color: #121313;
  cursor: pointer;
}

.char-creation-race-panel .race-panel .select:hover {
  background: #2963f5;
}
.char-creation-vitals-panel {
  display: flex;
  flex-direction: column;
  align-items: center;
  color: #aed7da;
}
.char-creation-vitals-panel .vital-item {
  display: flex;
  flex-direction: row;
  width: 100%;
}
.char-creation-vitals-panel .vital-item .vital-desc {
  flex: 1 50%;
  padding: 1rem;
}
.char-creation-vitals-panel .vital-item .vital-desc p {
  margin: 1rem 0rem;
}
.char-creation-vitals-panel .vital-item .vital-info {
  display: flex;
  flex-direction: column;
  flex: 1 50%;
  justify-content: center;
  align-items: center;
  text-align: center;
  font-size: 1.2rem;
}
.char-creation-vitals-panel .vital-item .vital-info label {
  margin-top: 0.5rem;
  color: #ff8b1f;
}
.char-creation-vitals-panel .vital-item .vital-info input {
  height: 40px;
  width: 400px;
  padding: 5px;
  background: #0e201e;
  color: #e0e0e0;
  border: solid 2px #268fa1;
  text-align: center;
  font-size: 1.2rem;
}
.char-creation-vitals-panel .vital-item .vital-info .hmp {
  position: relative;
  height: 50px;
  width: 400px;
  padding: 5px;
  background: #0e201e;
  color: #e0e0e0;
  border: solid 2px #268fa1;
  text-align: center;
  font-size: 1.2rem;
  cursor: pointer;
}
.char-creation-vitals-panel .vital-item .vital-info .hmp button {
  position: absolute;
  right: 0;
  top: 0;
  height: 46px;
  width: 46px;
  background: #268fa1;
  border: none;
  outline: none;
  cursor: pointer;
  padding: 5px;
}
.char-creation-vitals-panel .vital-item .vital-info .hmp button img {
  height: 90%;
}
.char-creation-vitals-panel .vital-item .vital-info .languages {
  display: flex;
  flex-direction: column;
  width: 300px;
  min-height: 150px;
  margin: 1rem;
  border: solid 1px #268fa1;
}
.char-creation-vitals-panel .vital-item .vital-info .languages div {
  padding: 0rem;
  border-bottom: solid 1px #4d4d4d;
  height: 30px;
  width: 100%;
  background: #0e201e;
}
.char-creation-vitals-panel .vital-item .vital-info .languages select {
  margin-top: auto;
  background: #ff8b1f;
  height: 30px;
  width: 100%;
}
.char-creation-vitals-panel .alignment {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.char-creation-vitals-panel .alignment p {
  margin: 1rem;
}
.char-creation-vitals-panel .alignment div {
  display: grid;
  grid-template-columns: repeat(3, 120px);
  grid-template-rows: repeat(3, 120px);
}
.char-creation-vitals-panel .alignment div div {
  font-size: 1.25rem;
  border: solid 1px #268fa1;
  color: #ff8b1f;
  align-items: center;
  text-align: center;
}
.char-creation-vitals-panel .alignment div div:hover {
  background: #0e201e;
  color: #d61fb5;
  cursor: pointer;
}
.char-creation-vitals-panel .alignment div div.selected-align {
  background: #ff8b1f;
  color: #0e201e;
}
.char-creation-vitals-panel h1 {
  width: 100%;
  color: #2963f5;
  font-size: 1.5rem;
  text-align: center;
  margin-top: 1rem;
}
.char-creation-background-panel {
  display: flex;
  flex-direction: column;
  color: #aed7da;
}
.char-creation-background-panel h1 {
  color: #2963f5;
  font-size: 1.5rem;
  margin-top: 1.5rem;
  margin-left: 1.5rem;
}
.char-creation-background-panel textarea {
  border: solid 1px #268fa1;
  background: #0e201e;
  padding: 1rem;
  color: #aed7da;
  font-size: 1.2rem;
  outline: none;
  resize: none;
  width: 90%;
  align-self: center;
}
.char-creation-background-panel textarea:focus {
  border: solid 1px #aed7da;
}
.char-creation-skills-panel {
  padding: 1rem;
}

.char-creation-skills-panel .skill-top {
  display: flex;
  flex-direction: row;
  width: 100%;
  padding: 2rem;
  color: #aed7da;
}

.char-creation-skills-panel .skill-top .skill-info {
  flex: 1 75%;
}

.char-creation-skills-panel .skill-top .skill-points-container {
  flex: 1 25%;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
}

.char-creation-skills-panel
  .skill-top
  .skill-points-container
  .skill-points-item {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.char-creation-skills-panel
  .skill-top
  .skill-points-container
  .skill-points-item
  div {
  width: 50px;
  margin-left: 1rem;
  font-size: 1.5rem;
  text-align: center;
  border: solid 1px #268fa1;
  color: #ff8b1f;
}

.char-creation-skills-panel .skill-row {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  border: solid 1px #268fa1;
  width: 100%;
  height: 60px;
  margin-bottom: 0.5rem;
  padding: 0.25rem 0.5rem;
}

.char-creation-skills-panel .skill-row .select-skill {
  flex: 1 60px;
  display: flex;
  align-items: center;
}

.char-creation-skills-panel .skill-row .select-skill button {
  padding: 5px 10px;
  background: #ff8b1f;
  cursor: pointer;
}

.char-creation-skills-panel .skill-row .skill-name {
  flex: 1 150px;
  font-size: 1.2rem;
  line-height: 1.2rem;
  padding-left: 10px;
  color: #e0e0e0;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  text-align: left;
}

.char-creation-skills-panel .skill-row .skill-mod {
  flex: 1 50px;
  font-size: 1rem;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}
.char-creation-skills-panel .skill-row .skill-mod input {
  border: solid 1px #268fa1;
  color: #e0e0e0;
  font-size: 1.1rem;
  border-radius: 50%;
  width: 32px;
  padding: 4px 4px;
  text-align: center;
}
.char-creation-skills-panel .skill-row .skill-att {
  flex: 1 50px;
  font-size: 1.25rem;
  color: #4d4d4d;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}
.char-creation-skills-panel .skill-row .skill-level {
  flex: 1 100px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
.char-creation-skills-panel .skill-row .skill-level .skill-level-value {
  font-size: 1.25rem;
  color: #e0e0e0;
  border: solid 1px #4d4d4d;
  background: #e0e0e0;
  color: #121313;
  border-radius: 20%;
  text-align: center;
  width: 35px;
  height: 35px;
  margin-left: 0.5rem;
}
.char-creation-skills-panel .skill-row .skill-level button {
  height: 25px;
  width: 25px;
  background: #ff8b1f;
  margin: 0 5px;
  border-radius: 20%;
}
.char-creation-skills-panel .skill-row .skill-level-chart {
  flex: 10 500px;
  font-size: 1.5rem;
  color: #2963f5;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-evenly;
  padding: 0rem 1rem;
}
.char-creation-skills-panel .skill-row .skill-level-chart .perk {
  width: 12px;
  height: 20px;
  border: solid 1px #4d4d4d;
  border-radius: 20%;
  background-color: none;
  margin-right: 5px;
}
.char-creation-skills-panel .skill-row .skill-level-chart .skill-mark {
  width: 5px;
  height: 20px;
  border: solid 1px #4d4d4d;
  border-radius: 20%;
  background-color: none;
  margin-right: 5px;
}

.char-creation-skills-panel .skill-row .skill-level-chart .filled {
  background: #d61fb5;
}

.char-creation-skills-panel .skill-row .expertise_container {
  flex: 2 120px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  color: #aed7da;
  font-size: 1.2rem;
  line-height: 1.2rem;
  padding: 0.5rem;
}
.char-creation-skills-panel .skill-row .expertise_container .expertise_select {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  background: #0e201e;
  border: solid 1px #268fa1;
  height: 40px;
  width: 120px;
}

.char-creation-skills-panel .skill-row.selected {
  background: #ff8b1f;
}
.char-creation-skills-panel .skill-row.selected .skill-name {
  color: #0e201e;
}

.char-creation-skills-panel .skill-row.selected .select-skill button {
  background: #268fa1;
}

.char-creation-skills-panel .skill-header {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  width: 100%;
  height: 60px;
  margin-bottom: 0.5rem;
  padding: 0.25rem 0.5rem;
}

.char-creation-skills-panel .skill-header .select-skill {
  flex: 1 60px;
}

.char-creation-skills-panel .skill-header .skill-name {
  flex: 1 150px;
  line-height: 1.2rem;
  padding-left: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  font-size: 1.5rem;
  color: #2963f5;
}
.char-creation-skills-panel .skill-header .skill-mod {
  flex: 1 50px;
  font-size: 1.5rem;
  color: #2963f5;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}
.char-creation-skills-panel .skill-header .skill-att {
  flex: 1 50px;
  font-size: 1.5rem;
  color: #2963f5;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}
.char-creation-skills-panel .skill-header .skill-level {
  flex: 1 100px;
  font-size: 1.5rem;
  color: #2963f5;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
.char-creation-skills-panel .skill-header .skill-level-chart {
  flex: 10 500px;
  font-size: 1.5rem;
  color: #2963f5;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-evenly;
  padding: 0rem 1rem;
}
.char-creation-skills-panel .skill-header .expertise_container {
  flex: 2 120px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  font-size: 1.5rem;
  color: #2963f5;
  line-height: 1.2rem;
  padding: 0.5rem;
}
.char-creation-skills-panel .skill-header :hover {
  cursor: default;
}

.char-creation-attributes-panel {
  display: flex;
  flex-direction: column;
  align-items: center;
  color: #aed7da;
  padding: 2rem;
}
.char-creation-attributes-panel h1 {
  margin-top: 1rem;
  width: 100%;
  color: #2963f5;
}
.char-creation-attributes-panel .att-row {
  display: flex;
  flex-direction: row;
  width: 100%;
  padding: 1rem;
}

.char-creation-attributes-panel .att-desc {
  flex: 1 50%;
}

.char-creation-attributes-panel .att-desc p {
  margin-bottom: 1rem;
}

.char-creation-attributes-panel .att-row .att-item {
  display: flex;
  flex-direction: row;
  flex: 1 50%;
  justify-content: center;
  align-items: center;
  text-align: center;
  font-size: 1.2rem;
  color: #ff8b1f;
}
.char-creation-attributes-panel .att-row .att-item div {
  margin-right: 2rem;
}
.char-creation-attributes-panel .att-row .att-item p {
  width: 60px;
  height: 60px;
  font-size: 2rem;
  line-height: 3.6rem;
  text-align: center;
  border: solid 2px #268fa1;
  border-radius: 30%;
  color: #e0e0e0;
}
.char-creation-attributes-panel .att-row .att-item .att-mod {
  position: relative;
  width: 40px;
  padding: 2px 0;
  background: #aed7da;
  border: 2px solid #268fa1;
  color: #121313;
  text-align: center;
  font-size: 1.25rem;
  border-radius: 50%;
  margin: auto;
  top: -10px;
}
.att-item button {
  height: 60px;
  width: 60px;
  margin-left: 2rem;
  border-radius: 30%;
  font-size: 1.5rem;
  font-weight: bold;
  outline: none;
}
.att-item .minus {
  background: #ff8b1f;
  cursor: pointer;
}
.att-item .plus {
  background: #d61fb5;
  cursor: pointer;
}

.att-item .disabled {
  background: #4d4d4d;
}

.char-sheet-inventory-panel {
  display: flex;
  flex-direction: column;
  color: #aed7da;
}

.char-sheet-inventory-panel .inventory-top-container {
  display: flex;
  flex-direction: row;
  margin-top: 1rem;
  margin-bottom: 2rem;
}

.char-sheet-inventory-panel .inventory-top-container h1 {
  color: #2963f5;
}

.char-sheet-inventory-panel .inventory-top-subcontainer {
  flex: 1 50%;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
}

.char-sheet-inventory-panel .inventory-top-container .inventory-top-subcontainer .funds-container {
  flex: 1 33%;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}

.char-sheet-inventory-panel .inventory-top-container .inventory-top-subcontainer .funds-container input {
  text-align: center;
  font-size: 1.2rem;
  border: solid 1px #268fa1;
  width: 100px;
  height: 40px;
  margin: 0.5rem 0;
}

.char-sheet-inventory-panel .inventory-top-container .inventory-top-subcontainer .capacity-container {
  flex: 1 67%;
  text-align: center;
}

.char-sheet-inventory-panel
  .inventory-top-container
  .inventory-top-subcontainer
  .capacity-container
  .capacity-border {
  border: solid 1px #268fa1;
  width: 300px;
  height: 40px;
  margin: 0.5rem auto;
}
.char-sheet-inventory-panel
  .inventory-top-container
  .inventory-top-subcontainer
  .capacity-container
  .capacity-border
  input {
  background: none;
  padding: 0.2rem;
  width: 60px;
  height: 100%;
  font-size: 1.25rem;
  margin: 0 0.5rem;
  text-align: center;
}
.char-sheet-inventory-panel
  .inventory-top-container
  .inventory-top-subcontainer
  .capacity-container
  .capacity-fill {
  position: absolute;
  background: #ff8b1f;
  height: 38px;
  width: 0px;
  z-index: -1;
}

.char-sheet-inventory-panel .inventory-top-container .action-container {
  flex: 1 40%;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}

.char-sheet-inventory-panel
  .inventory-top-container
  .action-container
  .action-button-container {
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-evenly;
  margin: 0.5rem 0;
}

.char-sheet-inventory-panel
  .inventory-top-container
  .action-container
  .action-button-container
  button {
  flex: 1 25%;
  height: 40px;
  margin: 0 0.5rem;
  background: #268fa1;
  color: #0e201e;
  font-size: 1.2rem;
  font-weight: bold;
  cursor: pointer;
}

.char-sheet-inventory-panel .itemList {
  flex: 1;
  overflow-y: auto;
}

.char-sheet-inventory-panel .itemList .itemContainer {
  display: flex;
  flex-direction: row;
  margin-bottom: 0.8rem;
  align-items: center;
  border: solid 1px #0e201e;
  padding: 0.5rem;
}

.char-sheet-inventory-panel .itemList .itemContainer .itemSubcontainer {
  flex: 1 33%;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}

.char-sheet-inventory-panel .itemList .equipped {
  border: solid 1px #ff8b1f;
  padding: 0.5rem;
}


.char-sheet-inventory-panel .itemList .itemContainer .itemName {
  flex: 1 40%;
  color: #ff8b1f;
  margin-right: 1rem;
}

.char-sheet-inventory-panel .itemList .itemContainer .itemType {
  flex: 1 20%;
  color: #d61fb5;
}

.char-sheet-inventory-panel .itemList .itemContainer .itemSubtype {
  flex: 1 20%;
  color: #d61fb5;
}

.char-sheet-inventory-panel .itemList .itemContainer .itemDesc {
  flex: 1 35%;
}

.char-sheet-inventory-panel .itemList .itemContainer .itemDesc div {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
}

.char-sheet-inventory-panel .itemList .itemContainer .itemDesc p {
  color: #e0e0e0;
  margin-right: 2rem;
}

.char-sheet-inventory-panel .itemList .itemContainer .inventoryOptions {
  flex: 1 10%;
  display: flex;
  flex-direction: column;
}

.char-sheet-inventory-panel .itemList .itemContainer .inventoryOptions div {
  display: flex;
  justify-content: center;
  padding: 0 0.5rem;
}

.char-sheet-inventory-panel .itemList .itemContainer .inventoryOptions p {
  width: 80px;
  height: 30px;
  text-align: center;
  border: solid 1px #ff8b1f;
}

.char-sheet-inventory-panel
  .itemList
  .itemContainer
  .inventoryOptions
  div:first-of-type
  button {
  width: 100%;
  height: 30px;
  background: #0e201e;
  color: #ff8b1f;
  font-size: 1.2rem;
  cursor: pointer;
}

.char-sheet-inventory-panel
  .itemList
  .itemContainer
  .inventoryOptions
  div:last-of-type
  button {
  width: 50%;
  height: 30px;
  background: #4d4d4d;
  color: #aed7da;
  font-size: 1.2rem;
  cursor: pointer;
}

.char-sheet-inventory-panel .itemList .itemContainer .quantMod {
  flex: 1 5%;
  justify-content: center;
  text-align: center;
  align-items: center;
}

.char-sheet-inventory-panel .itemList .itemContainer .quantMod span {
  font-size: 1.25rem;
  padding: 0.5rem 1rem;
  color: #268fa1;
  border: solid 1px #aed7da;
}

.char-sheet-inventory-panel .itemList .itemContainer .quantMod button {
  padding: 0.5rem 0.5rem;
  background: #268fa1;
  font-size: 1.2rem;
  border: solid 1px #aed7da;
  border-radius: 10px;
  font-weight: bold;
  cursor: pointer;
}

.offhandModal {
  background: #121313;
  color: #aed7da;
  position: absolute;
  top: 400px;
  left: 25%;
  right: 25%;
  min-height: 150px;
  border: solid 2px #268fa1;
  display: flex;
  flex-direction: column;
  text-align: center;
  align-items: center;
}

.offhandModal h1 {
  color: #2963f5;
}

.offhandModal button {
  height: 40px;
  width: 120px;
  margin: 0 1rem;
  margin-top: 1.2rem;
  font-size: 1.2rem;
  background: #ff8b1f;
  font-weight: bold;
}

.offhandOverlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(49, 49, 49, 0.6);
}

.itemStore {
  background: #121313;
  color: #aed7da;
  position: absolute;
  top: 100px;
  left: 10%;
  right: 10%;
  bottom: 50px;
  border: solid 2px #268fa1;
}

.itemStoreOverlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(49, 49, 49, 0.6);
}

.itemStore .storeWrapper {
  display: flex;
  flex-flow: column;
  height: 100%;
}



.itemStore .closeStoreModal {
  position:absolute;
  top: 0;
  right: 0;
  background: #d42323;
  color:#e0e0e0;
  padding: 0.5rem;
  text-align: center;
}

.itemStore .storeWrapper .storeHeader {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  padding: 2rem;
  border-bottom: solid 1px #444747;
}

.itemStore .storeWrapper .storeHeader h1 {
  color: #2963f5;
  margin-bottom: 1rem;
  margin-right: 4rem;
}

.itemStore .storeWrapper .storeHeader .storeSubheader {
  display: flex;
  flex-direction: row;
  align-items: center;
  text-align: center;
}

.itemStore .storeWrapper .storeHeader select {
  background: #444747;
  color: #aed7da;
  height: 40px;
  width: 200px;
  text-align: center;
  font-size: 1.2rem;
}

.itemStore .storeWrapper .storeHeader .storeFunds {
  justify-self: flex-end;
  display: flex;
  right: 0;
  padding: 1rem 2rem;
  display: flex;
  align-items: center;
}

.itemStore .storeWrapper .storeHeader .storeFunds span {
  font-size: 1.2rem;
  margin-right: 1rem;
}

.itemStore .storeWrapper .storeHeader .storeFunds div {
  border: solid 1px #ff8b1f;
  padding: 0.5rem 1rem;
  color: #e0e0e0;
}

.itemStore .storeWrapper .itemList {
  flex: 1;
  padding: 0rem 1rem;
  overflow-y: auto;
}

.itemStore .storeWrapper .itemList .itemContainer {
  display: flex;
  flex-direction: row;
  margin-bottom: 0.8rem;
  align-items: center;
  border: solid 1px #0e201e;
  padding: 0.25rem;
}

.itemStore .storeWrapper .itemList .itemContainer .itemNameType {
  flex: 1 33%;
  display: flex;
  flex-direction: row;
}

.itemStore .storeWrapper .itemList .itemContainer .itemNameType .itemName {
  flex: 1 60%;
  color: #56c4d7;
}

.itemStore .storeWrapper .itemList .itemContainer .itemNameType .itemType {
  flex: 1 40%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  color: #d61fb5;
}

.itemStore .storeWrapper .itemList .itemContainer .itemDesc {
  flex: 1 40%;
}

.itemStore .storeWrapper .itemList .itemContainer .itemDesc div {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
}

.itemStore .storeWrapper .itemList .itemContainer .itemDesc p {
  margin-right: 2rem;
}

.itemStore .storeWrapper .itemList .itemContainer .storeOptions {
  flex: 1 10%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.itemStore .storeWrapper .itemList .itemContainer .storeOptions p {
  width: 100%;
  height: 30px;
  text-align: center;
  border: solid 1px #ff8b1f;
}

.itemStore .storeWrapper .itemList .itemContainer .storeOptions .storeButtons{
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  text-align: center;
  width: 100%;
  margin-top: 0.25rem;
}

.itemStore .storeWrapper .itemList .itemContainer .storeOptions button {
  width: 50%;
  height: 30px;
  background: #268fa1;
  font-size: 1.2rem;
}

.itemStore .storeWrapper .storeFooter {
  display: flex;
  min-height: 80px;
  background: #0e201e;
  align-items: center;
}

.itemStore .storeWrapper .storeFooter .storeSubfooter {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  white-space: nowrap;
}

.itemStore .storeWrapper .storeFooter h2 {
  flex: 1 20%;
  color: #2963f5;
  padding: 0 2rem;
}

.itemStore .storeWrapper .storeFooter p {
  flex: 1 10%;
  padding: 0 2rem;
}

.itemStore .storeWrapper .storeFooter .storeFooterButtons {
  flex: 1 40%;
  display: flex;
  justify-content: space-evenly;
  text-align: center;
}

.itemStore .storeWrapper .storeFooter .storeFooterButtons button {
  width: 120px;
  height: 40px;
  font-size: 1.2rem;
}

.itemStore .storeWrapper .storeFooter .storeFooterButtons button:first-of-type {
  background: #2963f5;
  color: #e0e0e0;
}

.itemStore .storeWrapper .storeFooter .storeFooterButtons button:last-of-type {
  background: #30060f;
  color: #e0e0e0;
}

.itemStore .craftingWrapper {
  display: flex;
  flex-direction: row;
  height: 100%;
}

.itemStore .craftingWrapper .craftingContainer {
  flex: 1 70%;
  display: flex;
  flex-direction: column;
}

.itemStore .craftingWrapper .craftingContainer .craftingHeader {
  flex: 1 15%;
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 2rem;
  color: #2963f5;
}

.itemStore .craftingWrapper .craftingContainer .craftingHeader .recipeButton {
  display: none;
}

.itemStore
  .craftingWrapper
  .craftingContainer
  .craftingHeader 
  .closeCraftingModal {
  position:absolute;
  top: 0;
  right: 0;
  background: #d42323;
  color:#e0e0e0;
  padding: 0.5rem;
  text-align: center;
}

.itemStore
  .craftingWrapper
  .craftingContainer
  .craftingHeader
  .craftableCheck
  input {
  height: 15px;
  width: 15px;
  margin-right: 0.5rem;
}

.itemStore
  .craftingWrapper
  .craftingContainer
  .craftingHeader
  .craftableCheck
  label {
  font-size: 16pt;
  color: #ff8b1f;
}

.itemStore .craftingWrapper .craftingContainer .recipeContainer {
  flex: 1 85%;
  position: relative;
  text-align: center;
  display: flex;
  flex-direction: column;
}

.itemStore .craftingWrapper .craftingContainer .recipeContainer .recipeVisual {
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content:  flex-end;
}

.itemStore .craftingWrapper .craftingContainer .recipeContainer .recipeBG {
  position: absolute;
  z-index: 0;
  opacity: 0.25;
  width: 50%;
  min-width: 500px;
  top: calc(25% - 200px);
  left: calc(50% - 300px);
}

.itemStore .craftingWrapper .craftingContainer .recipeContainer .weapBG {
  top: calc(40% - 200px);
}

.itemStore
  .craftingWrapper
  .craftingContainer
  .recipeContainer
  .recipeComponent {
  position: absolute;
  width: 240px;
  border: solid 2px #268fa1;
  border-radius: 0.5rem;
  background: #0e201e;
  color: #ff8b1f;
  font-size: 14pt;
  padding: 0.5rem;
}

.itemStore
  .craftingWrapper
  .craftingContainer
  .recipeContainer
  .recipeComponentNA {
  position: absolute;
  width: 240px;
  border: solid 2px #30060f;
  border-radius: 0.5rem;
  background: #4d4d4d;
  color: #0e201e;
  font-size: 14pt;
  padding: 0.5rem;
}

.itemStore .craftingWrapper .craftingContainer .recipeContainer .recipeYield {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
  font-size: 14pt;
  padding-bottom: 1rem;
}

.itemStore
  .craftingWrapper
  .craftingContainer
  .recipeContainer
  .recipeYield
  span {
  color: #d61fb5;
  margin-right: 1rem;
}

.itemStore .craftingWrapper .craftingContainer .recipeContainer .craftButton {
  width: 120px;
  height: 40px;
  font-size: 14pt;
  background: #268fa1;
  border-radius: 0.5rem;
  font-weight: bold;
}

.itemStore .craftingWrapper .craftingContainer .recipeContainer .craftButtonNA {
  width: 120px;
  height: 40px;
  font-size: 14pt;
  border: solid 2px #30060f;
  border-radius: 0.5rem;
  background: #4d4d4d;
  font-weight: bold;
}


.itemStore
  .craftingWrapper
  .craftingContainer
  .recipeContainer
  .simpleGrenComp1 {
  top: calc(10% - 20px);
  left: calc(50% - 120px);
}

.itemStore
  .craftingWrapper
  .craftingContainer
  .recipeContainer
  .simpleGrenComp2 {
  top: calc(40% - 20px);
  left: calc(50% - 120px);
}

.itemStore
  .craftingWrapper
  .craftingContainer
  .recipeContainer
  .simpleGrenComp3 {
  top: calc(70% - 20px);
  left: calc(25% - 120px);
}

.itemStore
  .craftingWrapper
  .craftingContainer
  .recipeContainer
  .simpleGrenComp4 {
  top: calc(70% - 20px);
  left: calc(75% - 120px);
}

.itemStore
  .craftingWrapper
  .craftingContainer
  .recipeContainer
  .simpleDroneComp1 {
  top: calc(25% - 20px);
  left: calc(25% - 120px);
}

.itemStore
  .craftingWrapper
  .craftingContainer
  .recipeContainer
  .simpleDroneComp2 {
  top: calc(25% - 20px);
  left: calc(75% - 120px);
}

.itemStore
  .craftingWrapper
  .craftingContainer
  .recipeContainer
  .simpleDroneComp3 {
  top: calc(60% - 20px);
  left: calc(50% - 120px);
}

.itemStore
  .craftingWrapper
  .craftingContainer
  .recipeContainer
  .simpleSerumComp1 {
  top: calc(20% - 20px);
  left: calc(50% - 120px);
}

.itemStore
  .craftingWrapper
  .craftingContainer
  .recipeContainer
  .simpleSerumComp2 {
  width: 160px;
  top: calc(60% - 20px);
  left: calc(30% - 80px);
}

.itemStore
  .craftingWrapper
  .craftingContainer
  .recipeContainer
  .simpleSerumComp3 {
    width: 160px;
  top: calc(60% - 20px);
  left: calc(50% - 80px);
}

.itemStore
  .craftingWrapper
  .craftingContainer
  .recipeContainer
  .simpleSerumComp4 {
    width: 160px;
  top: calc(60% - 20px);
  left: calc(70% - 80px);
}

.itemStore
  .craftingWrapper
  .craftingContainer
  .recipeContainer
  .simpleModComp1 {
  top: calc(60% - 20px);
  left: calc(25% - 120px);
}

.itemStore
  .craftingWrapper
  .craftingContainer
  .recipeContainer
  .simpleModComp2 {
  top: calc(40% - 20px);
  left: calc(50% - 120px);
}

.itemStore
  .craftingWrapper
  .craftingContainer
  .recipeContainer
  .simpleModComp3 {
  top: calc(20% - 20px);
  left: calc(75% - 120px);
}

.itemStore .craftingWrapper .recipeList {
  flex: 1 30%;
  background: #0e201e;
  border: solid 1px #4d4d4d;
  overflow-x: hidden;
  overflow-y: auto;
}

.itemStore .craftingWrapper .recipeList select {
  width: 100%;
  padding: 0.7rem 0rem 0.7rem 1rem;
  background: #268fa1;
  font-size: 18pt;
  font-weight: bold;
}

.itemStore .craftingWrapper .recipeList .craftableListItem {
  padding: 0.5rem 0rem 0.5rem 1rem;
  background: #4d4d4d;
  cursor: pointer;
  border: solid 1px #121313;
}

.itemStore .craftingWrapper .recipeList .selected {
  background: #d61fb5;
  color: #121313;
}

.itemStore .craftingWrapper .recipeList .craftableListItem:hover {
  background: #ff8b1f;
  color: #0e201e;
  cursor: pointer;
}

.editCampaignContainer {
  display: flex;
  flex-direction: column;
  border: solid 1px #0e201e;
  justify-content: center;
  align-items: center;
}

.editCampaignContainer > div {
  border: solid 1px #4d4d4d;
  padding: 1rem;
  color: #e0e0e0;
  margin: 1rem;
}

.editCampaignContainer .campInfo {
  margin: 0;
  border: none;
  background: #0e201e;
  padding: 2rem;
  width: 100%;
}

.editCampaignContainer .campInfo h1 {
  font-size: 32pt;
  color: #ff8b1f;
  margin-bottom: 1rem;
}

.editCampaignContainer .campInfo div {
  display: flex;
  flex-direction: row;
  margin-top: 1rem;
  align-items: center;
}

.editCampaignContainer .campInfo div p {
  color: #268fa1;
  margin-right: 3rem;
}

.editCampaignContainer .campInfo div p span {
  color: #aed7da;
}

.editCampaignContainer .campInfo div button {
  height: 30px;
  width: 60px;
  background: #4d4d4d;
  color: #121313;
  border: solid 1px #121313;
  border-radius: 5px;
}

.editCampaignContainer .campItem {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 98%;
  justify-content: flex-start;
  border: solid 1px #4d4d4d;
}

.editCampaignContainer .campItem h1 {
  color: #aed7da;
}

.editCampaignContainer .campItem .campItemButs {
  position: absolute;
  top: 0px;
  right: 0px;
  padding: 1rem;
}

.editCampaignContainer .campItem .campItemButs button {
  height: 40px;
  width: 120px;
  background: #ff8b1f;
  border: solid 1px #ffc085;
  border-radius: 5px;
}

.editCampaignContainer .areaList {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.editCampaignContainer .areaList .areaItem {
  display: flex;
  flex-direction: row;
}

.mapContainer {
  display: flex;
  justify-content: center;
  width: 100%;
  margin-top: -5rem;
}

/* Wiki */

.wikiHomeContainer {
  margin-top: 1rem;
  height: 80vh;
  width: 100%;
}

.wikiCreateContainer {
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 90vh;
}

.wikiCreateContainer .wikiCreateSubcontainer {
  display: flex;
  flex-direction: column;
  padding: 1rem;
  height: 100%;
  width: 100%;
}

.wikiCreateContainer .wikiCreateSubcontainer .wikiMarkdownEditor {
  height: 100%;
  width: 100%;
  background-color: #e0e0e0;
  color:#121313;
}

.wikiCreateContainer .wikiCreateSubcontainer .wikiMarkdownViewer {
  border: solid 1px #0e201e;
  height: 100%;
  width: 100%;
  color:#aed7da;
}

.wikiCreateContainer .wikiCreateSubcontainer .wikiMarkdownViewer h1 {
  color:#ff8b1f;
}

@media (max-width: 1200px) {
  .hide-med{
    display: none;
  }

  .edit-char-status-panel .health-container {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    text-align: center;
  }

  .edit-char-status-panel .health-subcontainer {
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: space-evenly;
    align-items: center;
    text-align: center;
  }

  .edit-char-status-panel .status {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    text-align: center;
  }
  
  .edit-char-status-panel .status-subcontainer {
    display: flex;
    width: 100%;
    flex-direction: row;
    justify-content: space-evenly;
    text-align: center;
  }

  .edit-char-status-panel .equipment-stims {
    display: flex;
    flex-direction: column;
    width: 100%;
  }

  .char-sheet-skill-panel {
    padding: 0rem;
  }

  

  .char-sheet-skill-panel .skill-row .skill-name {
    flex: 1 150px;
    font-size: 1rem;
    line-height: 1rem;
    color: #e0e0e0;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    text-align: left;
  }

  .char-sheet-skill-panel .skill-row .skill-level .skill-level-value {
    font-size: 1rem;
    color: #e0e0e0;
    border: solid 1px #4d4d4d;
    background: #e0e0e0;
    color: #121313;
    border-radius: 20%;
    text-align: center;
    width: 30px;
    height: 30px;
    margin-left: 0.5rem;
  }

  .char-sheet-skill-panel .skill-row .expertise_container {
    flex: 1 80px;
    font-size: 1rem;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .expertise_select {
    color: #aed7da;
    font-size: 1rem;
    line-height: 1rem;
    padding: 0.25rem;
    text-align: center;
    background: #4d4d4d;
    border: solid 1px #268fa1;
    height: 40px;
    width: 80px;
    max-width: 100%;
    white-space: normal;
    /* For Firefox: */
    text-overflow: ellipsis;
    -o-appearance: none;
    -ms-appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    cursor: pointer;
  }

  .attackModal {
    background: #121313;
    color: #aed7da;
    position: absolute;
    top: 0px;
    left: 10%;
    right: 10%;
    bottom: 0px;
    border: solid 2px #268fa1;
    display: flex;
    flex-direction: row;
  }

  .char-sheet-inventory-panel .itemList {
    flex: 1;
    overflow-y: auto;
  }

  .itemStore {
    background: #121313;
    color: #aed7da;
    position: absolute;
    top: 0;
    left: 10%;
    right: 10%;
    bottom: 0;
    max-height: 100%;
    border: solid 2px #268fa1;
  }
}

@media (max-width: 900px) {
  .hide-900 {
    display: none;
  }
  .edit-char-main-panel .first-row {
    display: flex;
    flex-direction: column;
  }

  .edit-char-main-panel .first-row .attributes .attribute-container .attribute-item input {
    width: 45px;
    height: 45px;
    font-size: 1.8rem;
    line-height: 3rem;
    text-align: center;
    border: solid 1px #268fa1;
    border-radius: 30%;
    color: #e0e0e0;
  }

  .char-sheet-skill-panel .skill-row {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    border: solid 1px #268fa1;
    width: 100%;
    margin-bottom: 0.5rem;
    padding: 0.25rem 0.5rem;
  }

  .char-sheet-skill-panel .skill-row .skill-subcontainer {
    margin: 0.25rem 0rem;
  }

  .attackModal {
    background: #121313;
    color: #aed7da;
    position: absolute;
    top: 65px;
    left: 5%;
    right: 5%;
    max-height: 100%;
    border: solid 2px #268fa1;
    display: flex;
    flex-direction: row;
  }

  .char-sheet-inventory-panel {
    display: flex;
    flex-direction: column;
    color: #aed7da;
  }

  .char-sheet-inventory-panel .inventory-top-container {
    display: flex;
    flex-direction: column;
    margin-top: 1rem;
    margin-bottom: 2rem;
  }

  .char-sheet-inventory-panel .inventory-top-subcontainer {
    flex: 1 50%;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    margin-bottom: 1rem;
  }

  .char-sheet-inventory-panel .itemList .itemContainer {
    display: flex;
    flex-direction: column;
    margin-bottom: 0.8rem;
    border: solid 1px #0e201e;
    padding: 0.5rem;
  }

  .char-sheet-inventory-panel .itemList .equipped {
    border: solid 1px #ff8b1f;
    padding: 0.5rem;
  }

  .char-sheet-inventory-panel .itemList .itemSubcontainer {
    margin-bottom: 1rem;
    justify-content: flex-start;
    align-items: flex-start;
  }

  .char-sheet-inventory-panel .itemList .itemDesc {
    margin-bottom: 1rem;
    justify-content: center;
    align-items: center;
    text-align: center;
  }

  .char-sheet-inventory-panel .itemList .itemContainer .inventoryOptions {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    width: 100%;
  }
  
  .char-sheet-inventory-panel .itemList .itemContainer .inventoryOptions div {
    display: flex;
    flex-direction: row;
    justify-content: center;
    padding: 0 0.5rem;
    width: 100%;
    max-width: 400px;
  }
  
  .char-sheet-inventory-panel
    .itemList
    .itemContainer
    .inventoryOptions
    div:first-of-type
    button {
    width: 100%;
    height: 30px;
    background: #0e201e;
    color: #ff8b1f;
    font-size: 1.2rem;
    cursor: pointer;
  }
  
  .char-sheet-inventory-panel
    .itemList
    .itemContainer
    .inventoryOptions
    div:last-of-type
    button {
    width: 50%;
    height: 30px;
    background: #4d4d4d;
    color: #aed7da;
    font-size: 1.2rem;
    cursor: pointer;
  }

  .char-sheet-inventory-panel .itemList .itemContainer .quantMod {
    margin-bottom: 1rem;
    justify-content: center;
    text-align: center;
    align-items: center;
  }
  
  .char-sheet-inventory-panel .itemList .itemContainer .quantMod span {
    padding: 0.5rem 1rem;
    color: #268fa1;
    border: solid 1px #aed7da;
  }

  .itemStore {
    background: #121313;
    color: #aed7da;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    max-height: 100%;
    border: solid 2px #268fa1;
  }

  .itemStore .storeWrapper .itemList .itemContainer .itemNameType {
    flex: 1 33%;
    display: flex;
    flex-direction: column;
  }

  .itemStore .storeWrapper .storeFooter {
    display: flex;
    flex-direction: column;
    padding: 0.25rem;
    background: #0e201e;
    align-items: center;
  }

  .itemStore .storeWrapper .storeFooter .storeSubfooter {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    text-align: center;
    white-space:normal;
  }
  
  .itemStore .storeWrapper .storeFooter h2 {
    flex: 0;
    color: #2963f5;
    font-size: 1.2rem;
  }

  .itemStore .storeWrapper .storeFooter .storeFooterButtons {
    flex: 1 40%;
    display: flex;
    justify-content: space-evenly;
    text-align: center;
  }
  
  .itemStore .storeWrapper .storeFooter .storeFooterButtons button {
    width: 120px;
    height: 40px;
    font-size: 1.2rem;
  }
  
  .itemStore .storeWrapper .storeFooter .storeFooterButtons button:first-of-type {
    background: #2963f5;
    color: #e0e0e0;
  }
  
  .itemStore .storeWrapper .storeFooter .storeFooterButtons button:last-of-type {
    background: #30060f;
    color: #e0e0e0;
  }

  

  .itemStore
  .craftingWrapper
  .craftingContainer
  .recipeContainer
  .recipeComponent {
  position: absolute;
  width: 240px;
  border: solid 2px #268fa1;
  border-radius: 0.5rem;
  background: #0e201e;
  color: #ff8b1f;
  font-size: 14pt;
  padding: 0.5rem;
}

.itemStore
  .craftingWrapper
  .craftingContainer
  .recipeContainer
  .recipeComponentNA {
  position: absolute;
  width: 240px;
  border: solid 2px #30060f;
  border-radius: 0.5rem;
  background: #4d4d4d;
  color: #0e201e;
  font-size: 14pt;
  padding: 0.5rem;
}

  .itemStore
  .craftingWrapper
  .craftingContainer
  .recipeContainer
  .simpleGrenComp1 {
  top: calc(10% - 20px);
  left: calc(50% - 120px);
}

.itemStore
  .craftingWrapper
  .craftingContainer
  .recipeContainer
  .simpleGrenComp2 {
  top: calc(30% - 20px);
  left: calc(50% - 120px);
}

.itemStore
  .craftingWrapper
  .craftingContainer
  .recipeContainer
  .simpleGrenComp3 {
  top: calc(50% - 20px);
  left: calc(50% - 120px);
}

.itemStore
  .craftingWrapper
  .craftingContainer
  .recipeContainer
  .simpleGrenComp4 {
  top: calc(70% - 20px);
  left: calc(50% - 120px);
}

.itemStore
  .craftingWrapper
  .craftingContainer
  .recipeContainer
  .simpleDroneComp1 {
  top: calc(10% - 20px);
  left: calc(50% - 120px);
}

.itemStore
  .craftingWrapper
  .craftingContainer
  .recipeContainer
  .simpleDroneComp2 {
  top: calc(30% - 20px);
  left: calc(50% - 120px);
}

.itemStore
  .craftingWrapper
  .craftingContainer
  .recipeContainer
  .simpleDroneComp3 {
  top: calc(50% - 20px);
  left: calc(50% - 120px);
}

.itemStore
  .craftingWrapper
  .craftingContainer
  .recipeContainer
  .simpleSerumComp1 {
  top: calc(10% - 20px);
  left: calc(50% - 120px);
}

.itemStore
  .craftingWrapper
  .craftingContainer
  .recipeContainer
  .simpleSerumComp2 {
  width: 160px;
  top: calc(30% - 20px);
  left: calc(50% - 80px);
}

.itemStore
  .craftingWrapper
  .craftingContainer
  .recipeContainer
  .simpleSerumComp3 {
    width: 160px;
  top: calc(50% - 20px);
  left: calc(50% - 80px);
}

.itemStore
  .craftingWrapper
  .craftingContainer
  .recipeContainer
  .simpleSerumComp4 {
    width: 160px;
  top: calc(70% - 20px);
  left: calc(50% - 80px);
}

.itemStore
  .craftingWrapper
  .craftingContainer
  .recipeContainer
  .simpleModComp1 {
  top: calc(15% - 20px);
  left: calc(50% - 120px);
}

.itemStore
  .craftingWrapper
  .craftingContainer
  .recipeContainer
  .simpleModComp2 {
  top: calc(35% - 20px);
  left: calc(50% - 120px);
}

.itemStore
  .craftingWrapper
  .craftingContainer
  .recipeContainer
  .simpleModComp3 {
  top: calc(55% - 20px);
  left: calc(50% - 120px);
}
}

@media (max-width: 700px) {
  .hide-sm {
    display: none;
  }
  .container {
    padding: 0;
  }
  .x-large {
    font-size: 3rem;
  }
  .large {
    font-size: 2rem;
  }
  .lead {
    font-size: 1.2rem;
  }
  .navbar {
    display: block;
    text-align: center;
  }
  .navbar ul {
    text-align: center;
    justify-content: center;
  }
  .navbar h1 {
    margin-bottom: 1rem;
  }
  .dash-buttons a {
    display: block;
    width: 100%;
    margin-bottom: 0.2rem;
  }
  .profile {
    grid-template-columns: 1fr;
    text-align: center;
    justify-content: center;
  }
  .profile ul {
    display: none;
  }
  .profile-grid {
    grid-template-areas:
      "top"
      "about"
      "exp"
      "edu"
      "github";
  }
  .profile-about .skills {
    flex-direction: column;
  }

  .dash-char-item .char-item-top {
    display: flex;
    flex-direction: column;
  }

  .dash-char-item .dash-char-name {
    font-size: 2rem;
  }

  .createCharWrapper {
    display: flex;
    flex-direction: column;
  }

  .char-header .header-menu {
    display: flex;
    flex-flow: row;
    align-items: center;
    justify-content: space-evenly;
    text-align: center;
  }

  .char-header .header-menu .header-tab {
    color: #aed7da;
    padding: 0.5rem;
    cursor: pointer;
    font-size: 0.85rem;
  }

  .edit-char-main-panel > div {
    margin-top:1rem;
  }

  .edit-char-main-panel .first-row {
    display: flex;
    flex-direction: column;
  }

  .edit-char-main-panel .second-row {
    display: flex;
    flex-direction: column;
  }

  .edit-char-main-panel .first-row .attributes {
    margin-top: 1rem;
  }

  .edit-char-main-panel .second-row .languages {
    margin-top: 1rem;
  }

  .edit-char-status-panel .health-subcontainer {
    display: flex;
    flex-direction: column;
    width: 100%;
    justify-content: space-evenly;
    align-items: center;
    text-align: center;
  }

  .edit-char-status-panel .equipment-stims .equipment .armor-container {
    display: flex;
    flex-direction: column;
    border: solid 1px #268fa1;
    justify-content: center;
    align-items: center;
    text-align: left;
  }
  
  .edit-char-status-panel .equipment-stims .equipment .armor-subcontainer {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }

  .edit-char-status-panel .equipment-stims .equipment .weapon-container {
    display: flex;
    flex-direction: column;
    border: solid 1px #268fa1;
    justify-content: center;
    align-items: center;
    text-align: left;
  }
  
  .edit-char-status-panel .equipment-stims .equipment .weapon-subcontainer {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }

  .char-sheet-skill-panel .skill-header {
    font-size: 1rem;
  }

  .expertise_select {
    color: #aed7da;
    font-size: 1rem;
    line-height: 1.2rem;
    padding: 0.25rem 0rem;
    text-align: center;
    background: #4d4d4d;
    border: solid 1px #268fa1;
    height: 50px;
    width: 80px;
    -o-appearance: none;
    -ms-appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    cursor: pointer;
  }

  .char-sheet-actions-panel {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0rem;
    border: solid 1px #0e201e;
    color: #e0e0e0;
  }

  .char-sheet-actions-panel
  .equipContainer {
    display: flex;
    flex-direction: column;
    padding: 0.5rem;
  }

  .attackModal {
    position: relative;
    top: 0px;
    left: 0px;
    background: #121313;
    color: #aed7da;
    border: solid 2px #268fa1;
    height: 100%;
  }

  .attackModal .attackPanel {
    flex: 1 100%;
    display: flex;
    flex-direction: column;
    overflow: auto;
  }

  .attackModal .sitchPanel{
    left: 0px;
    position: absolute;
    display: flex;
    background: #121313;
    color: #eee;
    overflow: auto;
    padding: 1rem;
    flex-direction: column;
    border: solid 1px #aed7da;
    transition: left 0.5s;
  }

  .attackModal .hidden {
    left: -200px;
  }

  .char-sheet-actions-panel .attackModalButtonContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .attackModal .attackPanel .attackPanelTop .sitchButton {
    display: flex;
    text-align: center;
    padding: 0.2rem 0.5rem;
    background: #aed7da ;
    color:#0e201e;
    cursor: pointer;
    border: solid 1px #4d4d4d;
    border-radius: 0.5rem;
    margin-left: 1rem;
    margin-bottom: 0.25rem;
  }

  .attackModal .attackPanel .attackModalWrapper .attackModalRow {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
    margin: 1rem 0rem;
  }
  

  .attackModal .attackPanel .attackModalWrapper .attackModalTotalRow {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
    padding: 0.5rem;
    border-top: solid 2px #2963f5;
    margin-top: 1rem;
    text-align: center;
    font-size: 1.5rem;
  }
  
  .attackModal .attackPanel .attackModalWrapper .blunt {
    border-top: none;
    margin-top: -1rem;
  }
  
  .attackModal .attackPanel .attackModalWrapper .attackModalTotalRow p {
    margin-right: 1rem;
  }
  
  .attackModal .attackPanel .attackModalWrapper .attackModalTotalRow p:last-of-type {
    color:#2963f5;
    margin-left: 1rem;
  }

  .char-sheet-inventory-panel > h1 {
    text-align: center;
  }

  .char-sheet-inventory-panel .inventory-top-container h1 {
    color: #2963f5;
    font-size: 1.2rem;
  }

  .char-sheet-inventory-panel .inventory-top-subcontainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-bottom: 1rem;
  }

  .char-sheet-inventory-panel .inventory-top-container .inventory-top-subcontainer .capacity-container {
    flex: 1 67%;
    text-align: center;
    margin-top: 1rem;
  }

  

  .itemStore .storeWrapper .storeHeader {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    padding: 0.25rem;
    margin-bottom: 1rem;
  }
  
  .itemStore .storeWrapper .storeHeader h1 {
    color: #2963f5;
    margin: 0rem;
    font-size: 1.5rem;
  }

  .itemStore .storeWrapper .storeHeader .storeFunds {
    justify-self: flex-end;
    display: flex;
    right: 0;
    padding: 0rem 2rem;
    display: flex;
    align-items: center;
  }
  
  .itemStore .storeWrapper .storeHeader .storeSubheader select {
    background: #444747;
    color: #aed7da;
    height: 40px;
    width: 120px;
    text-align: center;
    font-size: 1rem;
  }

  .itemStore .storeWrapper .itemList {
    flex: 1;
    padding: 0rem;
    overflow-y: auto;
    font-size: 0.85rem;
  }

  .itemStore .storeWrapper .itemList .itemContainer .storeOptions .storeButtons{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    width: 100%;
    margin-top: 0.25rem;
  }
  
  .itemStore .storeWrapper .itemList .itemContainer .storeOptions button {
    width: 100%;
    height: 30px;
    background: #268fa1;
    font-size: 1rem;
  }

  .lvlUpModal {
    background: #121313;
    color: #aed7da;
    position: absolute;
    top:10%;
    left: 0;
    right: 0;
    bottom: 10%;
    width: 100%;
    border: solid 2px #268fa1;
    overflow: auto;
  }
  
  .lvlUpOverlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(49, 49, 49, 0.6);
  }

  .lvlUpModal h1 {
    color: #ff8b1f;
    text-align: center;
  }
  
  .lvlUpModal .levelUpItem {
    display: flex;
    flex-direction: row;
  }
  
  .lvlUpModal .levelUpItem h2 {
    color: #268fa1;
    margin: 1rem 1rem;
    font-size: 1.2rem;
  }
  
  .lvlUpModal .levelUpItem p {
    margin: 1rem 1rem;
    font-size: 1.2rem;
  }
  
  .lvlUpModal .levelUpAttrItem {
    display: flex;
    flex-direction: column;
  }
  
  .lvlUpModal .levelUpAttrItem h2 {
    color: #268fa1;
    margin: 1rem 1rem;
    font-size: 1.2rem;
  }
  
  .lvlUpModal .levelUpAttrItem .levelUpAttrContainer {
    display: flex;
    flex-direction: row;
    justify-content: center;
  }
  
  .lvlUpModal .levelUpAttrItem .levelUpAttrContainer p {
    background: #0e201e;
    color: #aed7da;
    font-size: 1.2rem;
    border: 1px solid #56c4d7;
    border-radius: 0.5rem;
    width: 60px;
    text-align: center;
    margin: 0rem 0.5rem;
    cursor: pointer;
  }
  
  .lvlUpModal .levelUpAttrItem .levelUpAttrContainer p:hover {
    background: #aed7da;
    color: #0e201e;
  }
  
  .lvlUpModal .levelUpAttrItem .levelUpAttrContainer .selected {
    background: #ff8b1f;
    color: #0e201e;
  }
  
  .lvlUpModal .levelUpAttrItem .attrChangeInfo {
    text-align: center;
    margin: 0.5rem;
  }
  
  .lvlUpModal .levelUpAttrItem .attrChangeInfo span:first-of-type {
    color: #268fa1;
  }
  
  .lvlUpModal .levelUpConfirm {
    bottom: 5%;
    left: calc(50% - (200px / 2));
    width: 200px;
    margin-top: 1rem;
    background: #ff8b1f;
    color: #121313;
    font-size: 1.2rem;
    font-weight: bold;
    border: solid 1px #aed7da;
    border-radius: 0.5rem;
    text-align: center;
    padding: 0.5rem;
    cursor: pointer;
  }
  
  .lvlUpModal .levelUpConfirm:hover {
    background: #aed7da;
    color: #121313;
  }

  .itemStore .craftingContainer {
    flex: 1 100%;
    display: flex;
    flex-direction: column;
    overflow: auto;
  }

   .itemStore .recipeList {
    left: 0px;
    width: 200px;
    position: absolute;
    display: flex;
    background: #121313;
    color: #eee;
    max-height: 100%;
    overflow: auto;
    padding: 1rem;
    flex-direction: column;
    border: solid 1px #aed7da;
    transition: left 0.5s;
  }

   .itemStore .hidden {
    left: -200px;
  }

  .itemStore .craftingWrapper .craftingContainer .craftingHeader .recipeButton {
    display: flex;
    text-align: center;
    justify-content: center;
    align-items: center;
    padding: 0.5rem 0.5rem;
    background: #aed7da ;
    color:#0e201e;
    cursor: pointer;
    border: solid 1px #4d4d4d;
    border-radius: 0.5rem;
    margin-right: 1rem;
    margin-bottom: 0.25rem;
  }

  .itemStore .craftingWrapper .craftingContainer .recipeContainer .recipeVisual {
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content:  flex-end;
  }

  .itemStore .craftingWrapper .craftingContainer .recipeContainer .recipeBG {
    position: absolute;
    z-index: 0;
    opacity: 0.25;
    width: 50%;
    min-width: 500px;
    top: calc(40% - 200px);
    left: calc(50% - 300px);
  }

.itemStore .craftingWrapper .craftingContainer .recipeContainer .recipeYield {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  bottom: 1rem;
  left: 2rem;
  font-size: 14pt;
  padding-bottom: 1rem;
}


}
